import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Input, Radio, Stack, RadioGroup
} from "@chakra-ui/react";
import { HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { OrganisationUnitInterface } from "../../../../../../shared";
import { NewRateInterface, EditRateInterface } from "../../../../../../shared/type/rate.type";
import convertPriceCommaToDot from "../../../../../../utils/price/convertPriceCommaToDot";
import sanitizePriceInput from "../../../../../../utils/price/sanitizePriceInput";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CustomInputRadio from "../../../../../Forms/CustomInputRadio/CustomInputRadio";
import FormInput from "../../../../../Forms/FormInput/FormInput";
import { defaultRate } from "../Rates";

interface Props {
  mode: "add" | "edit",
  isOpen: boolean,
  initData?: NewRateInterface | EditRateInterface,
  onClose: () => void,
  EditRateInterface?: boolean
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    padding: '4px' +
      '',
    borderColor: '#ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#aaa',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#FFF7E6' : 'white',
    color: '#101828',
    padding: '8px',
    borderRadius: '8px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    color: '#333',
  }),
};

const AddEditRate = ({ mode, initData, isOpen, onClose }: Props) => {
  const { apiTenantOrganisationUnitController, apiTenantStakeController } = useApi();
  const { id: tenant } = useParams();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const [organizationUnits, setOrganizationUnits] = useState<OrganisationUnitInterface[]>([]);

  const [rate, setRate] = useState<NewRateInterface | EditRateInterface>(defaultRate);

  const fetchOrganizationUnits = async () => {
    try {
      const response = await apiTenantOrganisationUnitController("").get(`/${tenant}`);
      setOrganizationUnits(response.data);
      console.log(response.data);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleAddRate = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      let response;

      const body = {
        ...rate,
        price: convertPriceCommaToDot(rate.price) || "0.00",
        totalSalesLimit: rate.totalSalesLimit !== null ? Number(rate.totalSalesLimit) : null,
        salesLimitForPerson: rate.salesLimitForPerson !== null ? Number(rate.salesLimitForPerson) : null,
        tenantId: tenant
      };

      console.log(body)

      if (mode === "edit" && "id" in rate) {
        response = await apiTenantStakeController("").put(`/${rate.id}`, body);
      } else {
        response = await apiTenantStakeController("").post("", body);
      }

      if (response.status === HttpStatusCode.Ok) {
        setRate(defaultRate);
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie ${mode === 'add' ? 'dodano' : 'edytowano'} nową stawkę`,
          status: "success"
        });
        setShowAlert(true);
        onClose();
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd ${mode === 'add' ? 'dodawania' : 'edytowania'} stawki`,
          status: "error"
        });
        setShowAlert(true);
      }
    } catch (e: any) {
      setAlertProperties({
        timeout: 2000,
        title: `Błąd ${mode === 'add' ? 'dodawania' : 'edytowania'} wydawki`,
        description: undefined,
        status: "error"
      });
      setShowAlert(true);
      console.error(e);
    } finally {
    }
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    const parsedValue = type === "number" ? (value ? parseFloat(Number(value).toFixed(2)) : null) : value;

    setRate((prev) => ({
      ...prev,
      [name]: parsedValue
    }));
  };

  useEffect(() => {
    fetchOrganizationUnits();
    if (initData && mode === "edit") {
      setRate(initData);
    } else {
      setRate(defaultRate);
    }
  }, [isOpen, initData]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleAddRate}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">{mode === 'add' ? 'Dodaj' : 'Edytuj'} stawkę</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Podaj nazwę"
              inputParams={{
                required: true,
                name: "name",
                value: rate.name,
                onChange: updateState
              }}
            />
            <div className="flex flex-row items-start">
              <div className="flex basis-1/2 gap-spacing-xs">
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    type="date"
                    required
                    name="startDate"
                    value={rate.startDate}
                    onChange={updateState}
                  />
                </div>
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input
                    type="date"
                    required
                    name="endDate"
                    value={rate.endDate}
                    onChange={updateState}
                  />
                </div>
              </div>
            </div>
            <FormInput
              label="Cena (w zł)"
              placeholder="Podaj cenę"
              inputParams={{
                required: true,
                type: "text",
                name: "price",
                value: sanitizePriceInput(rate.price || ""),
                onChange: updateState
              }}
            />
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                isMulti
                styles={customStyles}
                placeholder="Wybierz klasy których dotyczą dni. Zostaw pole puste, żeby wybrać wszystkie klasy."
                value={organizationUnits
                  .filter((unit) => rate.organisationUnitIds.includes(unit.id))
                  .map((unit) => ({
                    label: unit.name,
                    value: unit.id
                  }))}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map((option) => option.value);

                  if (selectedValues.includes(-1)) {
                    setRate((prev) => ({
                      ...prev,
                      organisationUnitIds: organizationUnits.map((unit) => unit.id)
                    }));
                  } else {
                    setRate((prev) => ({
                      ...prev,
                      organisationUnitIds: selectedValues.includes(-1)
                        ? organizationUnits.map((unit) => unit.id)
                        : selectedValues,
                    }));
                  }
                }}
                options={[
                  { label: 'Wszyscy', value: -1 },
                  ...organizationUnits.map((unit) => ({
                    label: unit.name,
                    value: unit.id,
                  })),
                ]}
              />
            </div>
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Status</label>
              <RadioGroup
                onChange={(value) => {
                  setRate({ ...rate, active: value === "true" });
                }}
                value={`${rate.active || "true"}`}
              >
                <Stack direction="row" spacing="xs">
                  <CustomInputRadio
                    header="Aktywny"
                  >
                    <Radio
                      size="md"
                      value="true"
                      name="freeDay"
                      colorScheme="customOrange"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                  <CustomInputRadio
                    header="Nieaktywny"
                  >
                    <Radio
                      size="md"
                      value="false"
                      name="freeDay"
                      colorScheme="customOrange"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                </Stack>
              </RadioGroup>
            </div>
            <FormInput
              label="Limit dzienny placówki"
              placeholder="Podaj limit"
              inputParams={{
                type: "number",
                name: "totalSalesLimit",
                value: rate.totalSalesLimit !== null ? rate.totalSalesLimit : '',
                onChange: updateState
              }}
            />
            <FormInput
              label="Limit na osobę"
              placeholder="Podaj limit"
              inputParams={{
                type: "number",
                name: "salesLimitForPerson",
                value: rate.salesLimitForPerson !== null ? rate.salesLimitForPerson : '',
                onChange: updateState
              }}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit"
              }}
            >
              {mode === 'add' ? 'Dodaj' : 'Zapisz'}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditRate;
