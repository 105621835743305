import { AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { OrderForChildren } from "../shared/type/orderForParent.type";
import { ParentOrderContextInterface } from "../shared/type/parentOrderContext.type";
import { useApi } from "./ApiProvider";
import { useParentBasketContext } from "./ParentBasketProvider";

interface Props {
  children: ReactNode;
}

interface ParentOrderContextDateInterface {
  year: number;
  month: number;
}

interface ParentOrderContextProps {
  parentOrderContext: ParentOrderContextInterface | undefined;
  setParentOrderContextDate: React.Dispatch<React.SetStateAction<ParentOrderContextDateInterface>>;
  tenantId: number | undefined;
  setTenantId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ParentOrderContextContext = createContext<ParentOrderContextProps | undefined>(undefined);

export const useParentOrderContextContext = () => {
  const context = useContext(ParentOrderContextContext);
  if (!context) {
    throw new Error('useParentOrderContextContext must be used within a ParentOrderContextProvider');
  }
  return context;
};

const today = new Date();

export const ParentOrderContextProvider = ({ children }: Props) => {
  const { apiOrderController } = useApi();
  const { setUserData, addItemToBasket } = useParentBasketContext();
  const [parentOrderContext, setParentOrderContext] = useState<ParentOrderContextInterface>();
  const [ tenantId, setTenantId ] = useState<number | undefined>(undefined);
  const [skipDateInRequest, setSkipDateInRequest] = useState(true);
  const [parentOrderContextDate, setParentOrderContextDate] = useState<ParentOrderContextDateInterface>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
  });

  const fetchParentOrderContext = async () => {
    try {
      const requestBody: any = {
        tenantId,
        userId: null,
      };

      if (!skipDateInRequest) {
        requestBody.year = parentOrderContextDate.year;
        requestBody.month = parentOrderContextDate.month;
      }


      const response: AxiosResponse<ParentOrderContextInterface> = await apiOrderController('parent-order-context').post('', requestBody);

      setParentOrderContext(response.data);
      setUserData(response.data.consumerOrderContexts.map(consumer => ({
        consumerId: consumer.consumerId,
        saldo: consumer.saldo,
        creditLimit: consumer.creditLimit,
        provision: consumer.provision,
      })));

      response.data.consumerOrderContexts.forEach(consumer => {
        consumer.orderDays.forEach(orderDay => {
          orderDay.purchasableItems?.forEach(item => {
            if (item.orderCount > 1) {
              const newOrder: OrderForChildren = {
                tenantId: consumer.tenantId,
                consumerId: consumer.consumerId,
                useCredit: false,
                orderedItems: [{
                  purchasableItem: item,
                  count: item.orderCount,
                  when: orderDay.when
                }]
              };
              addItemToBasket(newOrder);
            }
          });
        });
      });
    } catch (error) {
      console.error("Error fetching parent order context:", error);
    } finally {
      setSkipDateInRequest(false);
    }
  };

  useEffect(() => {
    if (tenantId) {
      fetchParentOrderContext();
    }
  }, [tenantId, parentOrderContextDate]);

  const contextValue = useMemo(
    () => ({
      parentOrderContext,
      setParentOrderContextDate,
      tenantId,
      setTenantId,
    }),
    [
      parentOrderContext,
      setParentOrderContextDate,
      tenantId,
      setTenantId,
    ]
  );

  return (
    <ParentOrderContextContext.Provider value={contextValue}>
      {children}
    </ParentOrderContextContext.Provider>
  );
};
