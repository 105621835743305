import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider
} from "@chakra-ui/react";
import { HttpStatusCode, AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { ConsumerInterface } from "../../../../shared/type/consumer.type";
import handlePhoneNumberChange from "../../../../utils/handlePhoneNumberChange";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../Forms/FormInput/FormInput";

interface Props {
  isOpen: boolean,
  usersIds: number[],
  handleClose: () => void
}

const EditStackOfUser = ({ isOpen, usersIds, handleClose }: Props) => {
  const { apiTenantSpecialConsumerController } = useApi();
  const { id } = useParams<{ id: string, tenantId: string }>();
  const { setShow, setAlertProperties } = useAlertContext();
  const [employeeStack, setEmployeeStack] = useState<ConsumerInterface[]>([]);
  const [actualUserId, setActualUserId] = useState<number>(Math.min(...usersIds));

  const fetchEmployee = async () => {

    try {
      const response: AxiosResponse<ConsumerInterface[]> = await apiTenantSpecialConsumerController('employee-list').get(`/${id}`);
      const filteredEmployees = response.data.filter(item => usersIds.includes(Number(item.userId)));
      setEmployeeStack(filteredEmployees);
      console.log(filteredEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const upgradeUsers = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    for (const singleEmployee of employeeStack) {
      try {
        const response = await apiTenantSpecialConsumerController('special-consumer').put('', {
          tenantId: id,
          userId: singleEmployee.userId,
          consumerId: singleEmployee.consumerId,
          firstName: singleEmployee.firstName,
          lastName: singleEmployee.lastName,
          cardNumber: singleEmployee.cardNumber,
          phoneNumber: singleEmployee.phoneNumber
        });
        if (response.status === HttpStatusCode.Ok) {
          setAlertProperties({
            timeout: 5000,
            title: 'Sukces',
            description: 'Poprawnie zaktualizowano użytkowników',
            status: 'success',
          });
          setShow(true);
        }
      } catch (error: any) {
        if (error.response.status === HttpStatusCode.Conflict) {
          setAlertProperties({
            timeout: 5000,
            title: 'Błąd',
            description: `${error.response.data.errorMessage}`,
            status: 'error',
          });
          setShow(true);
          fetchEmployee();
        }
      }
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, [usersIds]);

  const handleInputChange = (key: keyof ConsumerInterface, value: string | number | null) => {
    setEmployeeStack(prevStack => {
      return prevStack.map(employee => {
        if (employee.userId === actualUserId) {
          return { ...employee, [key]: value };
        }
        return employee;
      });
    });
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="4xl" isCentered>
      <form onSubmit={upgradeUsers}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Edytuj dane użytkowników</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex w-full">
            <div className='w-60 flex flex-col gap-spacing-xs'>
              {employeeStack.map(employee => (
                <div
                  key={employee.userId}
                  className={`text-sm font-semibold rounded-md ${actualUserId === employee.userId ? 'bg-brand-100 text-grayLight-700' : 'text-grayLight-500'}`}
                  onClick={() => setActualUserId(employee.userId)}
                >
                  {employee.firstName} {employee.lastName}
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-spacing-xl flex-1">
              <FormInput
                label="Imię"
                placeholder="Podaj imię"
                inputParams={{
                  value: employeeStack.find(employee => employee.userId === actualUserId)?.firstName || "",
                  name: "firstName",
                  type: 'text',
                  required: true,
                  minLength: 3,
                  onChange: (e) => handleInputChange("firstName", e.target.value),
                }}
              />
              <FormInput
                label="Nazwisko"
                placeholder="Podaj nazwisko"
                inputParams={{
                  value: employeeStack.find(employee => employee.userId === actualUserId)?.lastName || "",
                  name: "lastName",
                  type: 'text',
                  required: true,
                  minLength: 3,
                  onChange: (e) => handleInputChange("lastName", e.target.value),
                }}
              />
              <FormInput
                label="Numer telefonu"
                placeholder="Podaj numer telefonu"
                inputParams={{
                  value: employeeStack.find(employee => employee.userId === actualUserId)?.phoneNumber || "",
                  name: "phoneNumber",
                  type: 'text',
                  required: true,
                  minLength: 3,
                  onChange: (e) => handleInputChange("phoneNumber", handlePhoneNumberChange(e.target.value)),
                }}
              />
              <FormInput
                label="Email"
                placeholder="Podaj email"
                inputParams={{
                  value: employeeStack.find(employee => employee.userId === actualUserId)?.login || "",
                  name: "login",
                  type: 'email',
                  required: true,
                  minLength: 3,
                  onChange: (e) => handleInputChange("login", e.target.value),
                }}
              />
              <FormInput
                label="Numer karty"
                placeholder="Podaj numer karty"
                inputParams={{
                  value: employeeStack.find(employee => employee.userId === actualUserId)?.cardNumber || "",
                  pattern: "\\d{10}",
                  title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
                  maxLength: 10,
                  required: false,
                  onWheel: (e) => (e.target as HTMLInputElement).blur(),
                  onInput: (e) => {
                    const input = e.target as HTMLInputElement;
                    input.value = input.value.replace(/[^0-9]/g, '');
                  },
                  onChange: (e) => handleInputChange("cardNumber", e.target.value),
                }}
              />
            </div>
          </ModalBody>
          <Divider className='pt-spacing-2xl pb-spacing-2xl'/>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <div>
              <CustomChakraButton
                hierarchy="secondaryColor"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  type: 'button',
                  className: "grow",
                  onClick: handleClose
                }}
              >
                Zamknij
              </CustomChakraButton>
            </div>
            <div className='w-48'>
              <CustomChakraButton
                hierarchy="primary"
                size="lg"
                iconPosition="onlyText"
                buttonProps={{
                  className: "grow w-48",
                  type: "submit",
                }}
              >
                Zapisz zmiany
              </CustomChakraButton>
            </div>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default EditStackOfUser;
