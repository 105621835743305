import { ReportInterface } from "../../../shared/type/report.type";
import SingleReport from "./SingleReport/SingleReport";

// | 'stakes-for-month'
// | 'orders-for-period'
// | 'meals-for-period'
// | 'meals-for-period-for-orgName'

const reports: ReportInterface[] = [
  {
    title: 'Podsumowanie miesiąca (zamówienia, wpłaty, zwroty)',
    apiUrl: 'month-summary',
    reportCriterion: {
      monthSelect: true
    }
  },
  {
    title: 'Lista dzieci',
    apiUrl: 'children-list',
    reportCriterion: undefined
  },
  {
    title: 'Statystyka posiłków wg stawek',
    apiUrl: 'month-stake-statistics',
    reportCriterion: {
      monthSelect: true
    }
  },
  {
    title: 'Zestawienie szczegółowe opłat na miesiąc',
    apiUrl: 'detailed-month-summary',
    reportCriterion: {
      monthSelect: true
    }
  },
  {
    title: 'Podsumowanie posiłków',
    apiUrl: 'stakes-for-month',
    reportCriterion: {
      monthSelect: true
    }
  },
  {
    title: 'Zamówienia i wpłaty',
    apiUrl: 'orders-for-period',
    reportCriterion: {
      dateScope: true
    }
  },
  {
    title: 'Posiłki na dziecko',
    apiUrl: 'meals-for-period',
    reportCriterion: {
      dateScope: true
    }
  },
  {
    title: 'Posiłki na dzień',
    apiUrl: 'meals-for-period-for-orgName',
    reportCriterion: {
      dateScope: true
    }
  },
  {
    title: 'Aktualnie salda',
    apiUrl: 'current-saldo',
    reportCriterion: {
      unitSelect: true
    }
  },
  {
    title: 'Zestawienie odebranych, dofinansowanych posiłków',
    apiUrl: 'cofinancing-summary-for-period',
    reportCriterion: {
      dateScope: true,
    }
  },
  {
    title: 'Lista nieobecności',
    apiUrl: 'absence-list-for-period',
    reportCriterion: {
      dateScope: true,
    }
  },
  {
    title: 'Lista nieobecności - podsumowanie dzieci',
    apiUrl: 'absence-summary-for-period',
    reportCriterion: {
      dateScope: true,
    }
  }
]

const Reports = () => {
  return (
    <section className="w-full h-full p-4 bg-grayBlue-25" style={{paddingBottom: '120px'}}>
      <div className="flex flex-col w-full h-full bg-white rounded-lg border box-border text-grayLight-200">
        <div className="w-full p-spacing-xl gap-1 border-b border-grayLight-200 box-border">
          <div className='flex flex-col gap-spacing-xxs'>
            <h2 className='mainHeaderOnPage'>Raporty</h2>
            <p className='mainHeaderOnPage__description'>Generuj raporty</p>
          </div>
        </div>
        <div className="flex w-full border-b border-grayLight-200">
          <div className='basis-7/12 pt-spacing-lg pr-spacing-xl pb-spacing-lg pl-spacing-xl'>
            <p className='tableHeader__text'>Nazwa raportu</p>
          </div>
          <div className='basis-3/12'></div>
          <div className="basis-2/12 pt-spacing-lg pr-spacing-xl pb-spacing-lg pl-spacing-xl">
            <p className='tableHeader__text'>Pobierz</p>
          </div>
        </div>
        <div className='flex flex-col py-spacing-3xl px-spacing-xl gap-spacing-3xl overflow-auto' >
          {reports.map((report: ReportInterface, index: number) => (
            <>
              <SingleReport report={report}/>
              {index < reports.length - 1 && (
                <hr className="my-4 border-grayLight-200" />
              )}
            </>
          ))}

        </div>
      </div>
    </section>
  )
};

export default Reports;