import './KidCharacterCard.css';

interface Props {
  firstName: string;
  lastName: string;
  remarks: string;
  organisationUnitName: string;
  cardNumber: string;
  saldo: string;
  tenantName: string;
}

const KidCharacterCard = ({ firstName, lastName, remarks, organisationUnitName, cardNumber, saldo, tenantName }: Props) => {


  return(
    <div className='flex flex-col lg:flex-row border box-border border-brand-200 p-spacing-xl lg:p-spacing-5xl lg:rounded-xl gap-4 bg-white'>
      <div className='basis-1/2 flex flex-col gap-4'>
        <div>
          <p className='KidCharacterCard__18 font-semibold text-grayLight-700'>{firstName} {lastName}</p>
        </div>
        <div className='pt-2 pb-2 flex flex-col gap-spacing-md'>
          <label className='text-sm font-normal text-grayLight-600'>Uwagi:</label>
          <p className='KidCharacterCard__18 text-grayLight-700 font-normal'>{remarks}</p>
        </div>
      </div>
      <div className='box-border border-r border-brand-200'/>
      <div className='basis-1/2 flex flex-col gap-4 lg:gap-0'>
        <div className='basis-1/2 flex lg:flex-row flex-col gap-3 justify-between'>
          <div className='flex flex-col gap-spacing-xs'>
            <label className="text-sm font-normal text-grayLight-600">Klasa:</label>
            <p className="KidCharacterCard__18 text-grayLight-600 font-semibold">{organisationUnitName}</p>
          </div>
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-normal text-grayLight-600">Identyfikator:</label>
            <p className="KidCharacterCard__18 text-grayLight-600 font-semibold">{cardNumber}</p>
          </div>
          <div className="flex flex-col gap-spacing-xs">
            <label className="text-sm font-normal text-grayLight-600">Saldo:</label>
            <p className="KidCharacterCard__18 text-grayLight-600 font-semibold">{saldo}</p>
          </div>
        </div>
        <div className="basis-1/2 flex flex-col">
          <label className='text-sm font-normal text-grayLight-600'>Szkoła:</label>
          <p className='KidCharacterCard__18 text-grayLight-600 font-semibold'>{tenantName}</p>
        </div>
      </div>
    </div>
  )
};

export default KidCharacterCard;