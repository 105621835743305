import { Menu, MenuButton, Box, MenuList, MenuItem, Divider } from "@chakra-ui/react";
import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { useParentBasketContext } from "../../../context/ParentBasketProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import useAuth from "../../../hooks/useAuth";
import { IconName } from "../../../shared";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../IconComponent/IconComponent";

import './ParentHeader.css';
import Basket from "../../Basket/Basket";
import PayOffTheBalance from "../../ChildOwnerComponents/PayOffTheBalance/PayOffTheBalance";

interface Props {
  dropdownMenuList: ReactNode;
  notificationList: ReactNode | null;
}

const ParentHeader = ({ dropdownMenuList, notificationList }: Props) => {
  const { basketStatus, setBasketStatus } = useParentDashboardContextContext();
  const { parentBasketContext } = useParentBasketContext();
  const { auth, logout } = useAuth();
  const [ showPayOfTheBalancePopUp, setShowPayOfTheBalancePopUp ] = useState<boolean>(false);
  const { parentDashboardContext } = useParentDashboardContextContext();
  const [showMenu, setShowMenu] = useState(false);

  const mobileMenuLinks: {
    icon: IconName,
    title: string,
    link: string,
  }[] = [
    {
      icon: "happyHomeIcon",
      title: "Strona główna",
      link: "/parent/main"
    },
    {
      icon: "calendar",
      title: "Zamówienia i obecności",
      link: "/parent/absences"
    },
    {
      icon: "cutlery",
      title: "Jadłospis",
      link: "/parent/meal-plan"
    },
    {
      icon: "users",
      title: "Twoi konsumenci",
      link: "/parent/assigned-accounts"
    },
    {
      icon: "history",
      title: "Historia zamówień",
      link: "/parent/order-history"
    },
    {
      icon: "message",
      title: "Wiadomości",
      link: "/parent/mail-box/messages"
    }
  ]

  return (
    <header className="w-full Header flex flex-row pt-spacing-xl pr-spacing-md pb-spacing-xl pl-4 lg:pl-8 lg:pr-8 lg:pt-2.5 lg:pb-2.5">
      <div className={`w-screen h-screen fixed flex flex-col top-0 right-0 bg-white z-50 transform transition-transform duration-300 ease-in-out ${showMenu ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="flex flex-row pr-4 pl-4">
            <div className="Header__selectContainer">
              <IconComponent iconName="logo" className="Header__logoContainer mr-spacing-xl w-20" />
            </div>
          <div
            className="flex flex-1 justify-end items-center"
            onClick={() => {
              setShowMenu(false);
            }}
          >
            <IconComponent iconName={showMenu ? 'xClose' : 'menu'} className="mr-spacing-xl w-3 h-3" />
          </div>
        </div>
        <div className="ml-2 grow flex flex-col justify-between">
          <div className="grid grid-rows-3 gap-spacing-2xl pt-spacing-3xl pb-spacing-3xl border-t border-grayLight-200">
            {
              mobileMenuLinks.map((link) => (
                <Link
                  to={link.link}
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  className="flex gap-spacing-lg items-center pr-spacing-3xl pl-spacing-3xl"
                >
                  <IconComponent iconName={link.icon} color='#667085'/>
                  <p className='font-semibold text-grayLight-500 text-base'>
                    {link.title}
                  </p>
                </Link>
              ))
            }
          </div>
          <div className='flex flex-col gap-spacing-2xl'>
            <div>
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                to="/parent/settings"
                className="flex gap-spacing-lg items-center pr-spacing-3xl pl-spacing-3xl"
              >
                <IconComponent iconName='settings' color='#667085'/>
                <p className='font-semibold text-grayLight-500 text-base'>
                  Ustawienia
                </p>
              </Link>
            </div>
            <Divider className="border-grayLight-200" />
            <div>
              <button
                type="button"
                onClick={logout}
                className="w-full box-border flex justify-between pt-spacing-3xl pr-spacing-md pb-spacing-3xl pl-spacing-2xl font-semibold text-base bg-none border-none mt-spacing-md"
              >
                <div className='flex flex-col items-start'>
                  <p
                    className='text-lg font-medium text-grayLight-700'
                  >{auth.loggedUserDto.firstName} {auth.loggedUserDto.lastName}</p>
                  <p
                    className='text-base font-normal text-grayLight-600'
                  >{auth.loggedUserDto.login}</p>
                </div>
                <div>
                  <IconComponent iconName="logOutDoor" color='#475467'/>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="Header__selectContainer hidden lg:flex">
        {parentDashboardContext?.showPaySaldoButton && (
          <CustomChakraButton
            hierarchy="secondaryColor"
            size="lg"
            iconPosition="left"
            icon="wallet"
            buttonProps={{
              onClick: () => {
                setShowPayOfTheBalancePopUp(true);
              }
            }}
          >Spłać saldo</CustomChakraButton>
        )}
      </div>
      <div
        className="Header__actionContainer flex flex-row-reverse lg:flex-row flex-1 justify-start lg:justify-end gap-spacing-xl lg:gap-spacing-md">
        <div
          className="flex justify-end items-center lg:hidden"
          onClick={() => {
            setShowMenu(true);
          }}
        >
          <IconComponent iconName="menu" className="mr-spacing-xl" />
        </div>
        <div className="ParentHeader__actions flex gap-4 items-center justify-center">
          <Menu>
            <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
              <div className="Header_ringIconContainer flex justify-center items-center cursor-pointer">
                <div className="absolute">
                  <IconComponent iconName="bell" color="#344054" />
                </div>
                <div
                  className="notificationCircle flex items-center justify-center relative bg-brand-600 border-white box-border border w-2.5 h-2.5 rounded-full">
                  {/* <p className='text-xs'>1</p> */}
                </div>
              </div>
            </MenuButton>
            <MenuList>{notificationList || (
              <MenuItem disabled>
                Brak powiadomień
              </MenuItem>
            )}</MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Box} aria-label="Options" className="cursor-pointer flex items-center">
              <div
                className="hidden ParentHeaderUsername lg:flex items-center justify-center w-9 h-9 bg-grayLight-100 border border-grayLight-300 rounded-full">
                <p className="ParentHeaderUsername__text m-o p-0">
                  {auth.loggedUserDto.firstName && auth.loggedUserDto.firstName.length > 0 && auth.loggedUserDto.firstName[0].toUpperCase()}
                  {auth.loggedUserDto.lastName && auth.loggedUserDto.lastName.length > 0 && auth.loggedUserDto.lastName[0].toUpperCase()}
                </p>
              </div>
            </MenuButton>
            <MenuList zIndex={999}>{dropdownMenuList}</MenuList>
          </Menu>
        </div>
        <button
          className={`ParentHeader__basketContainer flex items-center box-border justify-center p-spacing-md gap-spacing-sm rounded-md border border-transparent ${basketStatus.show && "  bg-brand-100 ParentHeader__basketContainer--shadow"}`}
          disabled={basketStatus.forceShow}
          onClick={() => setBasketStatus((prev) => ({ ...prev, show: !prev.show }))}>
          <p className="text-sm font-semibold text-grayLight-900">Koszyk</p>
          <div className="flex items-center justify-center">
            <div className="absolute">
              <IconComponent iconName="basket" color="#344054" />
            </div>
            <div
              className="basketPriceCircle flex items-center justify-center relative bg-brand-600 border border-white box-border w-4 h-4 rounded-full">
              <p className="text-xs font-normal text-grayLight-900">
                {parentBasketContext.ordersForChildren.flatMap(consumer => consumer.orderedItems).length}
              </p>
            </div>
          </div>
        </button>
      </div>
      <div className={`BasketContainer z-40 border-l bg-white transform transition-transform duration-300 ease-in-out ${basketStatus.show ? "translate-x-0" : "translate-x-full"}`}>
        <Basket />
      </div>
      {showPayOfTheBalancePopUp && <PayOffTheBalance isOpen={showPayOfTheBalancePopUp} onClose={() => {setShowPayOfTheBalancePopUp(false)}}/>}
    </header>
  );

};

export default ParentHeader;