import { Flex, TableContainer } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../context/ApiProvider";
import { PaginationInterface } from "../../../shared/type/paginationInterface.type";
import {
  ParentOrderHistoryInterfaceWithPagination,
  ParentOrderHistoryInterface, OrderItemInterface
} from "../../../shared/type/parentOrderHistory.type";
import translatePaymentType from "../../../utils/translatePaymentTyle";
import CustomChakraButton from "../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../DataTable/DataTable";
import Pagination from "../../Pagination/Pagination";
import ParentOrderHistoryDetails from "./ParentOrderHistoryDetails/ParentOrderHistoryDetails";

const ParentOrderHistory = () => {
  const { apiParentFinancialHistoryController } = useApi();
  const columnHelper = createColumnHelper<ParentOrderHistoryInterface>();
  const [ orderHistory, setOrderHistory ] = useState<ParentOrderHistoryInterface[]>([]);
  const [page, setPage] = useState({
    pageNumber: 1,
    maxPageNumber: 0
  });
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ selectedItem, setSelectedItem ] = useState<OrderItemInterface>();
  const [ showDetails, setShowDetails ] = useState<boolean>(false);

  const fetchOrderHistory = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ParentOrderHistoryInterfaceWithPagination> = await apiParentFinancialHistoryController('order-history').post('', {
        pageNumber: page.pageNumber,
        pageSize: 10,
      })
      const { data, next, hasNextPage, totalCount } = response.data;

      setActualPage({ next, hasNextPage, totalCount });
      setOrderHistory(data)
    } catch (error: any) {

    } finally {
      setIsLoading(false)
    }
  };

  const rowHeight: number = 50;

  const calculateRowsNumber = (productsQuantity: number | undefined, isSummary: boolean) => {
    let rowNumber = 0;

    if(productsQuantity){
      rowNumber += productsQuantity;
    }

    if(isSummary){
      rowNumber++;
    }

    return rowNumber
  }

  const borderColor = '#EAECF0';
  const columns = [
    columnHelper.accessor('orderDate', {
      cell: (info) => {
        const { orderItems, orderDate } = info.row.original;

        const date = new Date(orderDate);

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return (
          <td rowSpan={orderItems.length}>
            <p className="text-grayLight-600 font-normal text-sm">
              {date.toLocaleDateString()}<br />
              {hours}:{minutes}
            </p>
          </td>
        );
      },
      header: "Data operacji"
    }),
    columnHelper.accessor('orderItems', {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index} className='flex justify-start'>
                <div
                  className="flex flex-col items-start justify-center"
                  style={{ height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px` }}
                >
                  <p className="text-grayLight-900 font-medium text-sm underline underline-offset-2">
                    {item.consumerDto.firstName} {item.consumerDto.lastName}
                  </p>
                  <p className="text-grayLight-600 font-normal text-sm">
                    {item.consumerDto.organisationUnitName}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div>
                <div
                  className="flex items-center h-12"
                >
                </div>
              </div>
            )}
          </>
        );
      },
      header: "Dziecko"
    }),
    columnHelper.accessor("orderItems", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;
        console.log(orderItems.length);
        if (orderItems.length === 1 && orderItems[0].summaryItems.length === 0) {
          return <div
                   className='flex items-center justify-start' >
                   <p className="text-grayLight-600 font-normal text-sm">
                     Spłata salda
                   </p>
                 </div>;
        }
        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index}
                   style={{
                     borderBottom: (totalPayment !== null && index === 0) ? `1px solid ${borderColor}` : (index < orderItems.length - 1 ? `1px solid ${borderColor}` : 'none')
                   }}>
                <div
                  className='flex flex-col justify-center items-start'
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}
                >
                  {item.summaryItems.map((summaryItem, summaryIndex) => (
                    <div
                      key={summaryIndex}
                      className="flex items-center"
                      style={{
                        height: `calc(100% / ${calculateRowsNumber(item.summaryItems.length, totalPayment !== null)})`
                      }}
                    >
                      <p className="font-normal text-sm text-grayLight-600">
                        {summaryItem.amount}x {summaryItem.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}
              >
                <div className="flex items-center h-12">

                </div>
              </div>
            )}
          </>
        );
      },
      header: "Zamówienie"
    }),
    columnHelper.accessor("orderItems", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index}
                  style={{
                    borderBottom: (totalPayment !== null && index === 0) ? `1px solid ${borderColor}` : (index < orderItems.length - 1 ? `1px solid ${borderColor}` : 'none')
                  }}>
                <div
                  className='flex items-center justify-start'
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}
                >
                  <p className="text-grayLight-600 font-normal text-sm">
                    {translatePaymentType(item.paymentType)}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}
              >
                <div
                  className="flex items-center h-12"
                >

                </div>
              </div>
            )}
          </>
        );
      },
      header: "Rodzaj transakcji"
    }),
    columnHelper.accessor("orderItems", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;
        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index}
                  style={{
                    borderBottom: (totalPayment !== null && index === 0) ? `1px solid ${borderColor}` : (index < orderItems.length - 1 ? `1px solid ${borderColor}` : 'none')
                  }}>
                <div
                  className='flex items-center justify-start'
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}
                >
                  <p className="text-grayLight-600 font-normal text-sm">
                    {item.saldoBefore}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}
              >
                <div
                  className="flex items-center h-12"
                >
                  <p className="text-grayLight-900 text-sm font-medium uppercase">
                    Razem
                  </p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: "Saldo przed (zł)"
    }),
    columnHelper.accessor("orderDate", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index}
                  style={{
                    borderBottom: (totalPayment !== null && index === 0) ? `1px solid ${borderColor}` : (index < orderItems.length - 1 ? `1px solid ${borderColor}` : 'none')
                  }}>
                <div
                  className='flex items-center justify-start'
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}
                >
                  <p className="text-grayLight-600 font-normal text-sm">
                    {item.paymentValue}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}
              >
                <div
                  className="flex items-center h-12"
                >
                  <p className="text-grayLight-900 text-sm font-medium">
                    {totalPayment.payment}
                  </p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: "Wpłata (zł)"
    }),
    columnHelper.accessor("orderDate", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => (
              <div key={index}
                  style={{
                    borderBottom: (totalPayment !== null && index === 0) ? `1px solid ${borderColor}` : (index < orderItems.length - 1 ? `1px solid ${borderColor}` : 'none')
                  }}>
                <div
                  className='flex items-center justify-start'
                  style={{
                    height: `${rowHeight * calculateRowsNumber(item.summaryItems.length, totalPayment !== null)}px`
                  }}
                >
                  <p className="text-grayLight-900 font-normal text-sm">
                    {item.orderValueString}
                  </p>
                </div>
              </div>
            ))}
            {totalPayment !== null && (
              <div>
                <div
                  className="flex items-center h-12"
                  style={{
                    borderTop: `1px solid ${borderColor}`
                  }}
                >
                  <p className="text-grayLight-900 text-sm font-medium">
                    {totalPayment.orderValue}
                  </p>
                </div>
              </div>
            )}
          </>
        );
      },
      header: "Wartość zamówienia (zł)"
    }),
    columnHelper.accessor("orderDate", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => {
              const numberOfRows = calculateRowsNumber(item.summaryItems.length, totalPayment !== null);

              return (
                <div key={index} style={{ borderBottom: index < orderItems.length - 1 ? '1px solid #e0e0e0' : 'none' }}>
                  <div
                    className='flex items-center justify-start'
                    style={{
                      height: `${rowHeight * numberOfRows}px`
                    }}
                  >
                    <p className="text-grayLight-600 font-normal text-sm">
                      {item.saldoAfter}
                    </p>
                  </div>
                </div>
              );
            })}
            {totalPayment !== null && (
              <div
                style={{
                  borderTop: `1px solid ${borderColor}`
                }}
              >
                <div
                  className="flex items-center h-12"
                >
                </div>
              </div>
            )}
          </>
        );
      },
      header: "Saldo po (zł)"
    }),
    columnHelper.accessor("orderDate", {
      cell: (info) => {
        const { orderItems } = info.row.original;

        const { firstName, lastName, admin } = orderItems[0].doneBy;

        return (
          <>
            <div>
              <div
                className="flex justify-center items-center">
                <p className="text-grayLight-600 font-normal text-sm">
                  {admin && "ADMIN: "} {firstName} {lastName}
                </p>
              </div>
            </div>
          </>
        );
      },
      header: "Wykonano przez"
    }),
    columnHelper.accessor("orderDate", {
      cell: (info) => {
        const { orderItems, totalPayment } = info.row.original;

        return (
          <>
            {orderItems.map((item, index) => {
              const numberOfRows = calculateRowsNumber(item.summaryItems.length, totalPayment !== null);

              return (
                <div key={index} style={{ borderBottom: index < orderItems.length - 1 ? '1px solid #e0e0e0' : 'none' }}>
                  <div
                    className='flex items-center justify-start'
                    style={{
                      height: `${rowHeight * numberOfRows}px`
                    }}
                  >
                    <CustomChakraButton
                      buttonProps={{
                        onClick: () => {
                          setShowDetails(true);
                          setSelectedItem(item);
                        },
                        type: 'button'
                      }}
                      size='sm'
                      hierarchy='linkGray'
                      iconPosition='onlyText'>
                      Szczegóły
                    </CustomChakraButton>
                  </div>
                </div>
              );
            })}
            {totalPayment !== null && (
              <div>
                <div
                  className="flex items-center h-12"
                >
                </div>
              </div>
            )}
          </>
        );
      },
      header: ""
    })
];

  useEffect(() => {
    fetchOrderHistory()
  }, [page.pageNumber]);

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum,
    }));
  };

  return (
    <section className="flex flex-col">
      <div className="p-spacing-3xl gap-1">
        <div className="gap-spacing-2xl">
          <div className="gap-spacing-xl">
            <div className="gap-spacing-xs">
              <h2 className="font-semibold text-grayLight-900" style={{ fontSize: '30px' }}>Historia zamówień</h2>
            </div>
          </div>
        </div>
      </div>
      <Flex direction="column" className="grow justify-between rounded-lg">
        <Flex direction="column" flex="1" justifyContent="space-between" minH="500px">
          <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-8xl overflow-x-auto lg:overflow-x-visible scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full">
            <DataTable
              hideSearch
              disableFilters
              columns={columns}
              isLoading={isLoading}
              data={orderHistory}
            />
          </TableContainer>
          <Flex justify="center" mt="4" p="4">
            <Pagination
              pageNumber={page.pageNumber}
              maxPageNumber={page.maxPageNumber}
              hasNextPage={actualPage.hasNextPage}
              onPageChange={handlePageChange}
            />
          </Flex>
        </Flex>
      </Flex>
      {showDetails && (
        <ParentOrderHistoryDetails order={selectedItem} isOpen={showDetails} onClose={() => setShowDetails(false)} />
      )}
    </section>
  )
};

export default ParentOrderHistory;