import { HttpStatusCode } from "axios";
import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Divider, RadioGroup, Stack, Radio
} from "@chakra-ui/react";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { CustomOperationInterface } from "../../../../../shared/type/customOperation.type";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../CustomInput/CustomInput";
import CustomInputRadio from "../../../../Forms/CustomInputRadio/CustomInputRadio";

interface Props{
  isOpen: boolean,
  handleClose: () => void,
  consumerId: number
}

const AddCustomOperation = ({isOpen, handleClose, consumerId }: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();

  const [ customOperation, setCustomOperation ] = useState<CustomOperationInterface>({
    consumerId: 0,
    amount: 0,
    credit: true,
    description: ''
  });

  // const { user } = useEditTeacherOrEmployeeContext();

  const { apiTenantConsumerTransactionsController } = useApi();

  const handleAddCustomOperation = async () => {
    try {
      const {amount, credit, description} = customOperation;
      const response = await apiTenantConsumerTransactionsController('create-custom-transaction').post('', {
        consumerId,
        amount,
        credit,
        description
      });
      if(response.status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie dodanie nową operację`,
          status: "success"
        });
        setShowAlert(true);
        handleClose();
      }
    } catch (error: any) {
      setAlertProperties({
        timeout: 2000,
        title: "Błąd",
        description: `Błąd dodawania nowej operacji [${error.response.data.violations[0].fieldName} - ${error.response.data.violations[0].message}]`,
        status: "error"
      });
      setShowAlert(true);
    }
  }

  // useEffect(() => {
  //   if(teacherId){
  //     setCurrentUserId(teacherId)
  //   }
  //   if(employeeId){
  //     setCurrentUserId(employeeId)
  //   }
  // }, []);

  return(
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="3xl" isCentered>
      <form onSubmit={handleAddCustomOperation}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Dodaj ręcznie operację</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-3xl">
            <div className="w-1/2">
              <CustomInput
                inputParams={{
                  step: 0.01,
                  name: "amount",
                  onChange: (e) => {
                    setCustomOperation((prev) => ({...prev, [e.target.name]: e.target.value}))
                  },
                  required: true,
                  min: 0
                }}
                type="default"
                size="md"
                destructive={false}
                label="Podaj kwotę operacji w zł"
              />
            </div>
            <div className="flex flex-col gap-spacing-xs">
              <label className="text-sm font-medium text-grayLight-700">Rodzaj transakcji</label>
              <RadioGroup
                onChange={(value) => {
                  setCustomOperation((prev) => ({...prev, credit: value === "true"}))
                }}
              >
                <Stack direction="row" spacing="xs">
                  <CustomInputRadio
                    header="Uznanie"
                  >
                    <Radio
                      size="md"
                      value="false"
                      name="credit"
                      colorScheme="customOrange"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                  <CustomInputRadio
                    header="Obciążenie"
                  >
                    <Radio
                      size="md"
                      value="true"
                      name="credit"
                      colorScheme="customOrange"
                      className="flex flex-col"
                    />
                  </CustomInputRadio>
                </Stack>
              </RadioGroup>
            </div>
            <div className="flex flex-col">
              <label className="text-grayLight-700 text-sm">Opis</label>
              <textarea cols={30}
                        rows={3}
                        required={true}
                        name='description'
                        style={{ resize: "none" }}
                        onChange={(e) => {
                          setCustomOperation((prev) => ({...prev, [e.target.name]: e.target.value}))
                        }}
                        maxLength={500}
                        className="border border-gray-300 rounded-lg p-spacing-lg" />
              {/* <div className="flex justify-between"> */}
              {/*   <label className="text-grayLight-700 text-sm">maks. 500 znaków</label> */}
              {/*   <label className="text-grayLight-700 text-sm">{newKidInterface.remarks?.length}/500</label> */}
              {/* </div> */}
            </div>
          </ModalBody>
          <Divider className="pt-spacing-2xl pb-spacing-2xl" />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "button",
                onClick: handleAddCustomOperation
              }}
            >
              Zapisz
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AddCustomOperation;