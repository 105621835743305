import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter, Divider, Input
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { MassOrderContextInterface } from "../../../../../../shared/type/massOrder.type";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../../../IconComponent/IconComponent";

interface Props{
  isOpen: boolean,
  handleClose: () => void,
  consumerIds: number[]
}

const AddOrder = ({isOpen, handleClose, consumerIds}: Props) => {
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiMassOrderController } = useApi();
  const { id: tenantId } = useParams<{id: string}>();

  const [ massOrderContext, setMassOrderContext ] = useState<MassOrderContextInterface>();
  const [ stakesCount, setStakesCount ] = useState<{stakeId: number, count: number}[]>([]);
  const [ date, setDate ] = useState<{startDate: string, endDate: string}>({startDate: '', endDate: ''})

  const fetchMassOrderContext = async () => {
    try {
      const response: AxiosResponse<MassOrderContextInterface> = await apiMassOrderController('order-context').post('', {
        consumerIds
      });
      setMassOrderContext(response.data);
    } catch (error) {
      console.error('Error fetching mass order context:', error);
    }
  };

  const handleMassOrder = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const response = await apiMassOrderController('place-mass-order').post('', {
        tenantId: Number(tenantId),
        startDate: date.startDate,
        endDate: date.endDate,
        consumerIds,
        orderPositions: stakesCount.filter(item => item.count > 0).map(stake => ({stakeId: stake.stakeId, count: stake.count}))
      });
      if (response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          status: 'success',
          title: 'Sukces',
          description: 'Poprawnie dodano zamówienie',
          timeout: 2000
        });
        setShowAlert(true);
        handleClose();
      }
    } catch (error: any) {
      setAlertProperties({
        status: 'error',
        title: 'Błąd',
        description: `Błąd dodawania zamówienia ${error.response.data.errorMessages ? `: ${error.response.data.errorMessages[0]}.` : '.'}`,
        timeout: 2000
      });
      setShowAlert(true);
    }
  };

  const changeStackCountValue = (value: number, limit: number | null, stakeId: number) => {
    console.log("changeStackCountValue called with:", { value, limit, stakeId });
    setStakesCount(prevStakesCount =>
      prevStakesCount.map(stake =>
        stake.stakeId === stakeId
          ? {
            ...stake,
            count: Math.max(0, limit !== null ? Math.min(stake.count + value, limit) : stake.count + value)
          }
          : stake
      )
    );
    console.log(stakesCount);
  };

  useEffect(() => {
    fetchMassOrderContext()
  }, [tenantId]);

  useEffect(() => {
    if (massOrderContext?.stakes) {
      setStakesCount(
        massOrderContext.stakes.map((stake) => ({ stakeId: stake.id, count: 0 }))
      );
    }
  }, [massOrderContext]);

  return(
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="3xl" isCentered>
      <form onSubmit={handleMassOrder}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Dodaj zamówienie</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex flex-col gap-spacing-3xl">
            <div className="flex flex-col gap-spacing-md">
              <div className="flex basis-1/2 gap-spacing-xl">
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data od</label>
                  <Input
                    type="date"
                    lang="pl"
                    required
                    name="startDate"
                    min={massOrderContext?.minDate}
                    onChange={(e) => {
                      setDate(prev => ({
                        ...prev,
                        startDate: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="flex flex-col gap-spacing-xs">
                  <label className="text-sm font-medium text-grayLight-700">Data do</label>
                  <Input
                    type="date"
                    lang="pl"
                    required
                    name="endDate"
                    max={massOrderContext?.maxDate}
                    onChange={(e) => {
                      setDate(prev => ({
                        ...prev,
                        endDate: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-spacing-md">
              <div>
                <p className="text-sm font-medium text-grayLight-700">Wybierz stawkę lub stawki:</p>
              </div>
              <div>
                {massOrderContext?.stakes ?
                  <table className="w-full border-collapse">
                    <thead className="text-xs bg-grayBlue-25">
                    <tr>
                      <th className="border-gray-300 p-2 flex items-start">
                        <p>Nazwa stawki</p>
                      </th>
                      <th className="border-l border-r border-gray-300 p-2">
                        <p>Cena w zł</p>
                      </th>
                      <th className="border-gray-300 p-2">
                        <p>Ilość na osobę / dzień</p>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="text-sm font-medium text-grayLight-900">
                    {massOrderContext.stakes.map((stake, index) => {
                      const stakeCount = stakesCount.find((item) => item.stakeId === stake.id)?.count || 0;

                      return (
                        <tr key={stake.id} className={`border-t ${index % 2 === 0 ? 'bg-brand-50' : ''}`}>
                          <td className="flex items-start pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md font-medium text-grayLight-900">
                            {stake.name}
                          </td>
                          <td className="pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md border-l border-r">
                            {stake.price}
                          </td>
                          <td className="flex justify-center pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md">
                            <div className="flex w-36 h-11 rounded-lg border box-border border-grayLight-300">
                              <div className="basis-1/3 flex items-center justify-center">
                                <button
                                  type="button"
                                  className="w-full h-full flex items-center justify-center"
                                  disabled={stakeCount === 0}
                                  onClick={() => {
                                    changeStackCountValue(-1, stake.salesLimitForPerson, stake.id);
                                  }}
                                >
                                  <IconComponent iconName="minus" />
                                </button>
                              </div>
                              <div className="basis-1/3 flex items-center justify-center">
                                {stakeCount}
                              </div>
                              <div className="basis-1/3 flex items-center justify-center">
                                <button
                                  type="button"
                                  className="w-full h-full flex items-center justify-center"
                                  disabled={stakeCount === stake.salesLimitForPerson}
                                  onClick={() => {
                                    changeStackCountValue(1, stake.salesLimitForPerson, stake.id);
                                  }}
                                >
                                  <IconComponent iconName="plus" />
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table> : <p className='text-sm'>Brak wspólnych stawek dla wybranych konsumentów</p>}
              </div>
            </div>
          </ModalBody>
          <Divider className="pt-spacing-2xl pb-spacing-2xl" />
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: "submit",
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AddOrder;