import { Checkbox, TableContainer, Thead, Tr, Text, Th, Box, Tooltip, Flex } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import './ChildrenTable.css';
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useApi } from "../../../../../context/ApiProvider";
import { PaginatedKidInterface, KidInterface } from "../../../../../shared/type/kid.type";
import { PaginationInterface } from "../../../../../shared/type/paginationInterface.type";
import CustomBadge from "../../../../CustomBadge/CustomBadge";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import IconComponent from "../../../../IconComponent/IconComponent";
import Pagination from "../../../../Pagination/Pagination";
import ImportParentsFromFilePopUp
  from "../../Parents/ParentsTable/ImportParentsFromFilePopUp/ImportParentsFromFilePopUp";
import AddKid from "./AddKid/AddKid";
import AddOrder from "./AddOrder/AddOrder";
import DeleteKid from "./DeleteKid/DeleteKid";
import MoveKid from "./MoveKid/MoveKid";
import ResetSaldo from "./ResetSaldo/ResetSaldo";

const ChildrenTable = () => {
  const location = useLocation();
  const { id: tenantId } = useParams<{id: string}>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ searchValue, setSearchValue ] = useState<string>('');
  const columnHelper = createColumnHelper<KidInterface>();
  const { apiTenantConsumerController } = useApi();
  const [ selectedKidIds, setSelectedKidIds] = useState<number[]>([]);
  const [ deletedKidId, setDeletedKidId ] = useState<number>(0);
  const [ popUp, setPopUp ] = useState<{
    deleteKid: boolean,
    moveKid: boolean,
    resetSaldo: boolean,
    addKid: boolean,
    addOrder: boolean,
    importKids: boolean,
  }>({
    deleteKid: false,
    moveKid: false,
    resetSaldo: false,
    addKid: false,
    addOrder: false,
    importKids: false
  });
  const [children, setChildren] = useState<KidInterface[]>([]);
  const [actualPage, setActualPage] = useState<PaginationInterface>({
    totalCount: 0,
    hasNextPage: false,
    next: {
      limit: 0,
      offset: 0
    }
  });
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 30,
    maxPageNumber: 0
  });

  const handleChangePopUpState = (name: 'deleteKid' | 'addKid' | 'moveKid' | 'resetSaldo' | 'addOrder' | 'importKids', value: boolean) => {
    setPopUp((prev) => ({ ...prev, [name]: value }))
  }

  const handleCheckboxChange = (kidId: number) => {
    setSelectedKidIds((prevSelected) =>
      prevSelected.includes(kidId)
        ? prevSelected.filter((id) => id !== kidId)
        : [...prevSelected, kidId]
    );
  };

  let searchTimeout: NodeJS.Timeout;

  useEffect(() => {
    if(tenantId === '0') return;
    setIsLoading(true);

    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      fetchChildren();
    }, 1000);

    return () => clearTimeout(searchTimeout);
  }, [tenantId, page.pageNumber, page.pageSize, searchValue]);

  const handleOpenDeleteKidPopUp = (id: number) => {
    setDeletedKidId(0); // Reset ID
    handleChangePopUpState("deleteKid", false); // Zamknięcie popupu, by wywołać później zmianę
    setTimeout(() => {
      setDeletedKidId(id); // Ustawienie nowego ID
      handleChangePopUpState("deleteKid", true); // Otwarcie popupu
    }, 0); // Minimalne opóźnienie, aby React zauważył zmianę stanu
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => (
        <Checkbox
          colorScheme="customOrange"
          isChecked={children.length > 0 && selectedKidIds.length === children.length}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedKidIds(children.map((child) => child.id));
            } else {
              setSelectedKidIds([]);
            }
          }}
        />
      ),
      cell: ({ row }) => {
        const kidId = row.original.id;
        return (
          <Checkbox
            colorScheme="customOrange"
            isChecked={selectedKidIds.includes(kidId)}
            onChange={() => handleCheckboxChange(kidId)}
          />
        );
      },
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => {
        const { lastName, firstName, id } = info.row.original;

        const newPath = location.pathname.replace('children-table', `single-kid/${id}/orders`);

        return (
          <p className='text-grayLight-900 font-medium text-sm underline underline-offset-4'>
            <Link to={newPath}>
              {lastName} {firstName}
            </Link>
          </p>
        );
      },
      header: 'Nazwisko imię'
    }),
    columnHelper.accessor('organisationUnitName', {
      cell: (info) => {
        const organisationUnitName = info.getValue();

        return (
          <p className='text-grayLight-900 font-medium text-sm'>
            {organisationUnitName}
          </p>
        );
      },
      header: 'Klasa'
    }),
    columnHelper.accessor('saldo', {
      cell: (info) => {
        const saldo = info.getValue();
        const isNegative = saldo.includes('-');

        return (
          <div className='w-full h-full flex-col items-center justify-start'>
            <CustomBadge color={isNegative ? 'error' : 'success'} size='sm'>
              {saldo}
            </CustomBadge>
          </div>
        );
      },
      header: "Saldo"
    }),
    columnHelper.accessor('cardNumber', {
      cell: (info) => {
        const cardNumber = info.getValue();

        return (
          <p className='text-grayLight-900 font-normal text-sm'>
            {cardNumber ? cardNumber : '-'
            }</p>
        );
      },
      header: 'Karta/ Brelok'
    }),
    columnHelper.accessor('parents', {
      cell: (info) => {
        const parents = info.getValue();

        const newPath = (parentId: number) => location.pathname.replace('children/children-table', `parents/single-parent/${parentId}/list-of-orders`);

        const parentList = () => {
          if(parents.length === 0){
            return <li>-</li>
          }

          return parents.map(parent => <li className="text-grayLight-900 font-medium text-sm underline underline-offset-4 pt-1 pb-1 right-margin" key={parent.id}>
            <Link to={newPath(parent.id)}>
              {parent.lastName} {parent.firstName}
            </Link>
          </li>)
        }

        return (
         <ul className='flex flex-cole'>
           {parentList()}
         </ul>
        );
      },
      header: 'Rodzic/e'
    }),
    columnHelper.accessor('cofinancingList', {
      cell: (info) => {
        const cofinancingList = info.getValue();

        const institutionList = () => {
          if(cofinancingList.length === 0){
            return <li>-</li>
          }

          return cofinancingList.map(item => <li className="text-grayLight-900 font-medium text-sm underline underline-offset-4 pt-1 pb-1" key={item.id}>{item.cofinancingInstitutionName}</li>)
        }

        return (
          <ul className='flex flex-col'>
            {institutionList()}
          </ul>
        );
      },
      header: 'Dofinansowania'
    }),
    columnHelper.accessor('remarks', {
      cell: (info) => {
        const remarks = info.getValue();

        return (
          <div className='flex items-center'>
            {remarks ? (
              <Tooltip label={remarks} hasArrow placement='top' bg='#fff7e6' textColor='#101828' fontSize='md'>
                <button>
                  <IconComponent iconName='annotation-alert'/>
                </button>
              </Tooltip>
            ) : <p>-</p>}
          </div>
        );
      },
      header: "Uwagi"
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: (info) => {
        const { id } = info.row.original;

        return (
          <div className="flex gap-spacing-sm">
              <button
                onClick={() => {
                  handleOpenDeleteKidPopUp(id)
                }}
                className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
                Usuń
              </button>
          </div>
        );
      }
    })
  ];

  const fetchChildren = async () => {
    try {
      const response: AxiosResponse<PaginatedKidInterface> = await apiTenantConsumerController("paged-data").post("",
        {
          pageNumber: page.pageNumber,
          pageSize: page.pageSize,
          searchPhrase: searchValue,
          tenantId,
        });
      const { data, next, hasNextPage, totalCount } = response.data;
      setChildren(data);
      setActualPage({ next, hasNextPage, totalCount });
      setPage((prev) => ({
        ...prev,
        maxPageNumber: Math.ceil(totalCount / page.pageSize),
      }));
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  const handlePageChange = (pageNum: number) => {
    setPage((prev) => ({
      ...prev,
      pageNumber: pageNum,
    }));
  };

  return(
    <section className="ChildrenTable p-spacing-xl w-full pb-28">
      <Flex direction="column" className="grow justify-between rounded-lg pb-20">
        <Flex direction="column" flex="1" justifyContent="space-between" minH="500px">
        <TableContainer h="100%" flex="1" minH="500px">
          <DataTable
        disableFilters={true}
        customSearchAction={(value) => {setSearchValue(value)}}
        filterComponent={
          <div className='flex gap-spacing-md'>
            <CustomChakraButton size='sm' hierarchy='tertiaryColor' iconPosition='onlyText' buttonProps={{
              onClick: () => {handleChangePopUpState('resetSaldo', true)},
              disabled: selectedKidIds.length === 0
            }}>Wyzeruj saldo</CustomChakraButton>
            <CustomChakraButton size='sm' hierarchy='tertiaryColor' iconPosition='onlyText' buttonProps={{
              onClick: () => {handleChangePopUpState('addOrder', true)},
              disabled: selectedKidIds.length === 0
            }}>Dodaj zamówienie</CustomChakraButton>
            <CustomChakraButton size='sm' hierarchy='tertiaryColor' iconPosition='onlyText' buttonProps={{
              onClick: () => {handleChangePopUpState('moveKid', true)},
              disabled: selectedKidIds.length === 0,
            }}>Przenieś do klasy</CustomChakraButton>
          </div>
        }
        extraThead={
          <Thead>
            <Tr>
              <Th colSpan={columns.length + 1}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Dzieci
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Lista dzieci
                      </Text>
                    </Box>
                  </div>
                  <div className="flex gap-spacing-lg">
                    <CustomChakraButton
                      size="md"
                      hierarchy="linkGray"
                      iconPosition="left"
                      icon="import"
                      buttonProps={{
                        onClick: () => {
                          handleChangePopUpState('importKids', true)
                        }
                      }}
                    >
                      Importuj listę
                    </CustomChakraButton>
                    <CustomChakraButton
                      size="md"
                      hierarchy="primary"
                      iconPosition="left"
                      icon="plus"
                      buttonProps={{
                        onClick: () => {handleChangePopUpState('addKid', true)}
                      }}
                    >
                      Dodaj dziecko
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Tr>
          </Thead>
        }
        columns={columns}
        isLoading={isLoading}
        data={children}
      />
    </TableContainer>
          <Flex justify="center" mt="4" p="4">
            <Pagination
              pageNumber={page.pageNumber}
              maxPageNumber={page.maxPageNumber}
              hasNextPage={actualPage.hasNextPage}
              onPageChange={handlePageChange}
            />
          </Flex>
        </Flex>
      </Flex>
      {popUp.addKid && (
        <AddKid
          isOpen={popUp.addKid}
          handleClose={() => {
            handleChangePopUpState('addKid', false);
            fetchChildren();
          }}/>
      )}
      {popUp.moveKid && (
        <MoveKid
          kidIds={selectedKidIds}
          isOpen={popUp.moveKid}
          handleClose={() => {
            handleChangePopUpState('moveKid', false);
            fetchChildren();
          }}/>
      )}
      {popUp.addOrder && (
        <AddOrder
          isOpen={popUp.addOrder}
          consumerIds={selectedKidIds}
          handleClose={() => {
            handleChangePopUpState('addOrder', false)
            fetchChildren();
          }}
        />
      )}
      {popUp.resetSaldo && (
        <ResetSaldo
          isOpen={popUp.resetSaldo}
          consumerIds={selectedKidIds}
          handleClose={() => {
            handleChangePopUpState('addOrder', false)
            fetchChildren();
          }}
        />
      )}
      {popUp.deleteKid && (
        <DeleteKid
          isOpen={popUp.deleteKid}
          deleteId={deletedKidId}
          onConfirm={() => {
            handleChangePopUpState('deleteKid', false)
            fetchChildren();
          }}
          onCancel={() => {
            handleChangePopUpState('deleteKid', false)
          }}
        />
      )}
      {popUp.importKids && (
        <ImportParentsFromFilePopUp
          isOpen={popUp.importKids}
          onClose={() => {
            handleChangePopUpState('importKids', false)
            fetchChildren();
          }}
        />
      )}
    </section>
  )
};

export default ChildrenTable;