
function sanitizePriceInput(input: string): string {
  let sanitized = input.replace(/[^\d.,]/g, '');

  let firstDecimalIndex = -1;
  for (let i = 0; i < sanitized.length; i++) {
    if (sanitized[i] === '.' || sanitized[i] === ',') {
      if (firstDecimalIndex === -1) {
        firstDecimalIndex = i;
      } else {
        sanitized = sanitized.slice(0, i) + sanitized.slice(i + 1);
        i--;
      }
    }
  }

  sanitized = sanitized.replace(/\./g, ',');

  const commaIndex = sanitized.indexOf(',');
  if (commaIndex !== -1 && sanitized.length > commaIndex + 3) {
    sanitized = sanitized.slice(0, commaIndex + 3);
  }

  return sanitized;
}

export default sanitizePriceInput;