import { Divider } from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from "../../../../context/ApiProvider";
import { useParentBasketContext } from "../../../../context/ParentBasketProvider";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import { TPayOrderConfirmationResponse } from "../../../../shared/type/tpayResponse.type";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import StickyNotes from "../../../StickyNotes/StickyNotes";
import calculatePrice from "../../../../utils/calculators/calculatePrice";

const OrderSummary = () => {
  const { apiParentPaymentController } = useApi();
  const navigate = useNavigate();
  const { setBasketStatus } = useParentDashboardContextContext();
  const { parentDashboardContext, fetchParentDashboardContext } = useParentDashboardContextContext();
  const { orderSummary, resetBasket } = useParentBasketContext();
  const { setAlertProperties, setShow: setShowAlert } = useAlertContext();
  const hiddenFormRef = useRef<HTMLFormElement>(null);

  const [ transactionResponse, setTransactionResponse ] = useState<TPayOrderConfirmationResponse>()

  const findUserName = (consumerId: number): { firstName: string; lastName: string } => {
    const consumer = parentDashboardContext?.tenants
      .flatMap((item) => item.consumers)
      .find((item) => item.consumerDto.id === consumerId);

    return {
      firstName: consumer?.consumerDto.firstName || "",
      lastName: consumer?.consumerDto.lastName || "",
    };
  };

  const confirmOrder = async () => {
    try {
      const response: AxiosResponse<TPayOrderConfirmationResponse> = await apiParentPaymentController('confirm-orders').post('',  orderSummary?.placedOrders.map(order => order.id));
      if(response.status === HttpStatusCode.Ok && !response.data.tpayPaymentDto) {
        setAlertProperties({
          description: `Poprawnie złożono zamówienie`,
          timeout: 2000,
          title: "Sukces",
          status: "success",
        });
        setShowAlert(true);
        resetBasket();
        navigate('/parent/main');
        return
      }
      if(response.status === HttpStatusCode.Ok && response.data.tpayPaymentDto) {
        setTransactionResponse(response.data);
      }
    } catch (error: any) {
      setAlertProperties({
        description: `Wystąpił błąd podczas składania zamówienia`,
        timeout: 5000,
        title: "Błąd",
        status: "error",
      });
      setShowAlert(true);
    } finally {
      fetchParentDashboardContext()
    }
  };
  useEffect(() => {
    if (transactionResponse && transactionResponse.tpayPaymentDto && hiddenFormRef.current) {
      const submitButton = hiddenFormRef.current?.querySelector('input[type="submit"]') as HTMLInputElement;
      if (submitButton) {
        submitButton.click();
      } else {
        console.error('Submit button not found in hidden form.');
      }
    }
  }, [transactionResponse]);

  useEffect(() => {
    setBasketStatus({
      forceShow: false,
      show: false,
    });
    console.log(orderSummary);
  }, [setBasketStatus]);

  if (!orderSummary || !orderSummary.placedOrders) {
    return (
      <section className="OrderSummary w-full flex flex-col justify-start bg-grayLight-50">
        <div className='flex flex-col gap-spacing-lg pt-spacing-md pr-spacing-3xl pb-spacing-3xl pl-spacing-3xl'>
          <p className='font-semibold text-2xl text-grayLight-900'>Podsumowanie zamówienia</p>
          <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
            Sprawdź czy ilość zamówionych posiłków i wybrane daty się zgadzają. Następnie przejdź do płatności.
          </p>
        </div>
        <div className='flex flex-grow pr-spacing-3xl pl-spacing-3xl gap-spacing-3xl pb-spacing-5xl'>
          <div className='flex flex-col basis-4/6 rounded-2xl bg-white p-spacing-3xl gap-spacing-3xl'>
            <p>Koszyk jest pusty.</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <div className='w-full h-full'>
      <section className="OrderSummary w-full flex flex-col justify-start pb-spacing-9xl lg:pb-0 bg-grayLight-50">
        <div className='flex flex-col gap-spacing-lg pt-spacing-md pr-spacing-3xl pb-spacing-3xl pl-spacing-3xl'>
          <p className='font-semibold text-2xl text-grayLight-900'>Podsumowanie zamówienia</p>
          <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
            Sprawdź czy ilość zamówionych posiłków i wybrane daty się zgadzają. Następnie przejdź do płatności.
          </p>
        </div>
        <div
          className='flex flex-col lg:flex-row flex-grow pr-spacing-3xl pl-spacing-3xl gap-spacing-3xl pb-spacing-5xl'>
          {/* Sekcja główna z zamówieniami */}
          <div className='flex flex-col basis-4/6 rounded-2xl bg-white p-spacing-3xl gap-spacing-3xl'>
            {orderSummary.placedOrders.map((placedOrder) => (
              <div key={placedOrder.consumerId} className='flex flex-col gap-spacing-2xl'>
                <div
                  className='flex items-center justify-between pt-spacing-md pb-spacing-md border-b box-border border-brand-300'>
                  <div>
                    <p className="font-medium text-grayLight-700" style={{ fontSize: '16px' }}>
                      {findUserName(placedOrder.consumerId).firstName} {findUserName(placedOrder.consumerId).lastName}
                    </p>
                  </div>
                  <div className='flex items-center gap-spacing-sm'>
                    <p className='text-sm font-medium text-grayLight-700'>suma:</p>
                    <p className='font-semibold text-grayLight-900' style={{ fontSize: '16px' }}>
                      {calculatePrice(placedOrder.upPaymentCalculation?.basicToPay, 'zł') || '0.00'}
                    </p>
                  </div>
                </div>
                <div className='flex flex-col gap-spacing-md'>
                  {placedOrder.upPaymentCalculation?.totalToPay === 0.00 && (
                    <StickyNotes message='Kwota zamówienia zostanie pokryta z aktualnego salda.' />
                  )}
                  {placedOrder.upPaymentCalculation?.totalToPay < orderSummary.upPaymentCalculation?.basicCost && placedOrder.upPaymentCalculation.totalToPay !== 0.00 && (
                    <StickyNotes message='Kwota zamówienia częściowo zostanie pokryta z aktualnego salda.' />
                  )}
                  {(placedOrder.orderErrors || []).map((error, index) => (
                    <StickyNotes key={index} message={error} />
                  ))}
                </div>
                {(placedOrder.orderedDays || []).map((orderDay, index) => {
                  const date = new Date(orderDay.forWhen);
                  const dayOfWeek = date.toLocaleDateString('pl-PL', { weekday: 'long' });
                  const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

                  return (
                    <div key={index} className="flex flex-col gap-spacing-md">
                      <div>
                        <p
                          className="text-sm font-normal text-grayLight-700">{capitalizedDayOfWeek} ({orderDay.forWhen})</p>
                      </div>
                      <div className='flex flex-col gap-spacing-xs'>
                        {orderDay.positions.map((position, index) => (
                          <React.Fragment key={index}>
                            <div className='flex justify-between'>
                              <p>{position.count}x {position.name}</p>
                              <div>
                                <p>{calculatePrice(position.price, 'zł')}</p>
                              </div>
                            </div>
                            <Divider borderColor="gray.300" my={2} />
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            ))}
          </div>

          {/* Sekcja podsumowania zamówienia */}
          <div className="flex flex-col justify-between basis-2/6 rounded-2xl bg-white">
            <div className='flex flex-col'>
              {orderSummary.placedOrders.map((order, index) => (
                <div key={index} className='flex flex-col p-spacing-3xl gap-spacing-xl'>
                  <div>
                    <p className='font-semibold text-lg text-grayLight-800'>
                      {findUserName(order.consumerId).firstName} {findUserName(order.consumerId).lastName}
                    </p>
                  </div>
                  <div className='flex flex-col gap-spacing-xl'>
                    <div className="flex flex-col gap-spacing-xl">
                      <div className="flex">
                        <div className="basis-1/3">
                          <p className='text-sm font-medium text-grayLight-700'>Saldo:</p>
                        </div>
                        <div className="basis-1/3">
                          <p className="text-lg font-medium text-grayLight-700">
                            {calculatePrice(order.upPaymentCalculation?.initialSaldo, 'zł') || '0.00'}
                          </p>
                        </div>
                        <div className="basis-1/3"></div>
                      </div>
                      <div className="flex">
                        <div className="basis-1/3">
                          <p className="text-sm font-medium text-grayLight-700">Zamówienie:</p>
                        </div>
                        <div className="basis-1/3">
                          <p className="text-lg font-medium text-grayLight-700">
                            {calculatePrice(order.upPaymentCalculation?.basicCost, 'zł') || '0.00'}
                          </p>
                        </div>
                        <div className="basis-1/3"></div>
                      </div>
                      <div className="flex items-center">
                        <div className="basis-1/3">
                          <p className="text-sm font-medium text-grayLight-700">Do zapłaty:</p>
                        </div>
                        <div className="basis-1/3">
                          <p className="text-lg font-bold text-grayLight-700">
                            {calculatePrice(order.upPaymentCalculation?.totalToPay, 'zł') || '0.00'}
                          </p>
                        </div>
                        <div className="basis-1/3">

                        </div>
                      </div>
                    </div>
                    <Divider />
                    <div className="flex items-center gap-spacing-md">
                      <div className="basis-1/3">
                        <p className="text-sm font-medium text-grayLight-700">Saldo po transakcji:</p>
                      </div>
                      <div className="basis-1/3">
                        <p className="text-lg font-medium text-grayLight-700">
                          {calculatePrice(order.upPaymentCalculation?.afterSaldo, 'zł') || '0.00'}
                        </p>
                      </div>
                      <div className="basis-1/3"></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Podsumowanie końcowe */}
            <div className="flex flex-col p-spacing-3xl gap-spacing-xl">
              <div className="flex flex-col gap-spacing-xl">
                <div className="flex justify-between">
                  <p className="text-sm font-normal text-grayLight-700">Wartość zamówienia</p>
                  <p className="text-sm font-normal text-grayLight-700">
                    {calculatePrice(orderSummary.upPaymentCalculation?.basicCost, null) || '0.00'}
                  </p>
                </div>
                <div className='flex justify-between'>
                  <p className='text-xl font-semibold text-grayLight-900'>Łącznie do zapłaty</p>
                  <p className='text-xl font-semibold text-grayLight-900'>
                    {calculatePrice(orderSummary.upPaymentCalculation?.totalToPay, null) || '0.00'}
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-spacing-md'>
                <CustomChakraButton
                  size="2xl"
                  hierarchy="primary"
                  iconPosition="onlyText"
                  buttonProps={{
                    disabled: !orderSummary.orderPossible,
                    onClick: confirmOrder
                  }}
                >
                  Potwierdź zamówienie
                </CustomChakraButton>
                <p className='font-normal text-grayLight-600' style={{ fontSize: '16px' }}>
                  Twoje zamówienie zostanie uregulowane z istniejącego salda.
                </p>
              </div>
            </div>
          </div>
        </div>
        <form ref={hiddenFormRef} method="post" action={transactionResponse?.tpayPaymentDto.action}>
          <input type="hidden" name="amount" value={transactionResponse?.tpayPaymentDto.kwota} />
          <input type="hidden" name="description" value={transactionResponse?.tpayPaymentDto.opis} />
          <input type="hidden" name="crc" value={transactionResponse?.tpayPaymentDto.crc} />
          <input type="hidden" name="md5sum" value={transactionResponse?.tpayPaymentDto.md5sum} />
          <input type="hidden" name="result_url" value={transactionResponse?.tpayPaymentDto.result_url} />
          <input type="hidden" name="merchant_description"
                 value={transactionResponse?.tpayPaymentDto.merchant_description} />
          <input type="hidden" name="return_url" value={transactionResponse?.tpayPaymentDto.return_url} />
          <input type="hidden" name="return_error_url" value={transactionResponse?.tpayPaymentDto.return_error_url} />
          <input type="hidden" name="email" value={transactionResponse?.tpayPaymentDto.email} />
          <input type="hidden" name="imie" value={transactionResponse?.tpayPaymentDto.imie} />
          <input type="hidden" name="nazwisko" value={transactionResponse?.tpayPaymentDto.nazwisko} />
          <input type="hidden" name="online" value="0" />
          <input type="hidden" name="language" value={transactionResponse?.tpayPaymentDto.language} />
          <input type="hidden" value={transactionResponse?.tpayPaymentDto.id} name="id" id="id" />
          <input type="submit" className="hidden" id="submit" />
        </form>
      </section>
    </div>
  );
};

export default OrderSummary;
