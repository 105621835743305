import React, { createContext, useContext, ReactNode } from "react";
import axios, { AxiosInstance } from 'axios';
import { useNavigate } from "react-router-dom";
import {
  ApiPublicSecurityControllerUrl,
  ApiTenantControllerUrl,
  ApiCompanyControllerUrl,
  ApiTenantFreeWorkingDayControllerUrl,
  ApiTenantStatuteControllerUrl,
  ApiTenantOrganisationUnitControllerUrl,
  ApiTenantMealDistributionControllerUrl,
  ApiTenantAllergenControllerUrl,
  ApiTenantTenantControllerUrl,
  ApiTenantAdminControllerUrl,
  ApiTenantUserControllerUrl,
  ApiTenantLogEntitiesController,
  ApiTenantStakeControllerUrl,
  ApiTenantSpecialConsumerController,
  ApiConsumerFinancialHistoryControllerUrl,
  ApiTenantMealMealControllerUrl,
  ApiTenantMealControllerUrl,
  ApiTenantCourseControllerUrl,
  ApiTenantMealToDayControllerUrl,
  ApiTenantCoFinancingInstitutionControllerUrl,
  ApiTenantParentControllerUrl,
  ApiTenantParentImportControllerUrl,
  ApiOrderHistoryControllerUrl,
  ApiTenantConsumerControllerUrl,
  ApiAbsenceAndCourseControllerUrl,
  ApiTenantAdminBoughtStakesControllerUrl,
  ApiMassOrderControllerUrl,
  ApiTenantConsumerTransactionsControllerUrl,
  ApiTenantPdfMealMenuControllerUrl,
  ApiParentDashboardContextControllerUrl,
  ApiParentSaldoControllerUrl,
  ApiOrderControllerUrl,
  ApiParentPaymentControllerUrl,
  ApiOldAppImportControllerUrl,
  ApiSuperAdminFixUrl,
  ApiParentConsumerControllerUrl,
  ApiPrivateSecurityControllerUrl,
  ApiParentPaymentSimulationControllerUrl,
  ApiParentDataControllerUrl,
  ApiParentFinancialHistoryControllerUrl,
  ApiParentMessageControllerUrl,
  ApiParentMealToDayControllerUrl,
  ApiParentPdfMealMenuController,
  ApiTenantMessageControllerUrl,
  ApiTenantAdminPaymentControllerUrl,
  ApiReportControllerUrl,
  ApiParentRegistrationControllerUrl,
  ApiCaptchaController,
  ApiAdminDatController
} from "../api/axios.url";
import useAuth from '../hooks/useAuth';

interface ApiProviderProps {
  children: ReactNode;
}

interface ApiInstances {
  apiAdminDataControllerUrl: (url: ApiAdminDatController) => AxiosInstance;
  apiReportController: (url: ApiReportControllerUrl) => AxiosInstance;
  apiParentRegistrationController: (url: ApiParentRegistrationControllerUrl) => AxiosInstance;
  apiParentPdfMealMenuController: (url: ApiParentPdfMealMenuController) => AxiosInstance;
  apiAbsenceAndCourseController: (url: ApiAbsenceAndCourseControllerUrl) => AxiosInstance;
  apiParentMealToDayController: (url: ApiParentMealToDayControllerUrl) => AxiosInstance;
  apiCompanyController: (url: ApiCompanyControllerUrl) => AxiosInstance;
  apiConsumerFinancialHistoryController: (url: ApiConsumerFinancialHistoryControllerUrl) => AxiosInstance;
  apiMassOrderController: (url: ApiMassOrderControllerUrl) => AxiosInstance;
  apiOldAppImportController: (url: ApiOldAppImportControllerUrl) => AxiosInstance;
  apiSuperAdminFixController: (url: ApiSuperAdminFixUrl) => AxiosInstance;
  apiOrderController: (url: ApiOrderControllerUrl) => AxiosInstance;
  apiOrderHistoryController: (url: ApiOrderHistoryControllerUrl) => AxiosInstance;
  apiParentConsumerController: (url: ApiParentConsumerControllerUrl) => AxiosInstance;
  apiParentDashboardContextController: (url: ApiParentDashboardContextControllerUrl) => AxiosInstance;
  apiParentDataController: (url: ApiParentDataControllerUrl) => AxiosInstance;
  apiParentFinancialHistoryController: (url: ApiParentFinancialHistoryControllerUrl) => AxiosInstance;
  apiParentPaySaldoController: (url: ApiParentSaldoControllerUrl) => AxiosInstance;
  apiParentPaymentController: (url: ApiParentPaymentControllerUrl) => AxiosInstance;
  apiParentPaymentSimulationController: (url: ApiParentPaymentSimulationControllerUrl) => AxiosInstance;
  apiParentMessageController: (url: ApiParentMessageControllerUrl) => AxiosInstance;
  apiPrivateSecurityController: (url: ApiPrivateSecurityControllerUrl) => AxiosInstance;
  apiPublicSecurityController: (url: ApiPublicSecurityControllerUrl) => AxiosInstance;
  apiTenantAdminBoughtStakesController: (url: ApiTenantAdminBoughtStakesControllerUrl) => AxiosInstance;
  apiTenantAdminController: (url: ApiTenantAdminControllerUrl) => AxiosInstance;
  apiTenantAdminPaymentController: (url: ApiTenantAdminPaymentControllerUrl) => AxiosInstance;
  apiTenantAllergenController: (url: ApiTenantAllergenControllerUrl) => AxiosInstance;
  apiTenantCoFinancingInstitutionController: (url: ApiTenantCoFinancingInstitutionControllerUrl) => AxiosInstance;
  apiTenantConsumerController: (url: ApiTenantConsumerControllerUrl) => AxiosInstance;
  apiTenantConsumerTransactionsController: (url: ApiTenantConsumerTransactionsControllerUrl) => AxiosInstance;
  apiTenantController: (url: ApiTenantControllerUrl) => AxiosInstance;
  apiTenantCourseController: (url: ApiTenantCourseControllerUrl) => AxiosInstance;
  apiTenantFreeWorkingDayController: (url: ApiTenantFreeWorkingDayControllerUrl) => AxiosInstance;
  apiTenantLogEntitiesController: (url: ApiTenantLogEntitiesController) => AxiosInstance;
  apiTenantMealController: (url: ApiTenantMealControllerUrl) => AxiosInstance;
  apiTenantMealDistributionController: (url: ApiTenantMealDistributionControllerUrl) => AxiosInstance;
  apiTenantMealMealController: (url: ApiTenantMealMealControllerUrl) => AxiosInstance;
  apiTenantMealToDayController: (url: ApiTenantMealToDayControllerUrl) => AxiosInstance;
  apiTenantOrganisationUnitController: (url: ApiTenantOrganisationUnitControllerUrl) => AxiosInstance;
  apiTenantParentController: (url: ApiTenantParentControllerUrl) => AxiosInstance;
  apiTenantParentImportController: (url: ApiTenantParentImportControllerUrl) => AxiosInstance;
  apiTenantPdfMealMenuController: (url: ApiTenantPdfMealMenuControllerUrl) => AxiosInstance;
  apiTenantSpecialConsumerController: (url: ApiTenantSpecialConsumerController) => AxiosInstance;
  apiTenantStakeController: (url: ApiTenantStakeControllerUrl) => AxiosInstance;
  apiTenantStatuteController: (url: ApiTenantStatuteControllerUrl) => AxiosInstance;
  apiTenantTenantController: (url: ApiTenantTenantControllerUrl) => AxiosInstance;
  apiTenantUserController: (url: ApiTenantUserControllerUrl) => AxiosInstance;
  apiTenantMessageController: (url: ApiTenantMessageControllerUrl) => AxiosInstance;
  apiCaptchaController: (url: ApiCaptchaController) => AxiosInstance;

}

// const corsAnywhere = 'http://localhost:5000';
// const base = `${corsAnywhere}/http://localhost:8080`;
const base = ``;

const ApiContext = createContext<ApiInstances | undefined>(undefined);

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }: ApiProviderProps) => {
  const { auth, updateAuth } = useAuth();
  const navigate = useNavigate();
  const { restartAuth } = useAuth();

  const createApiInstance = (url: string): AxiosInstance => {
    const instance = axios.create({
      baseURL: `${url}`,
      headers: { Authorization: `Bearer ${auth.jwToken.jwToken}` }
    });

    instance.interceptors.request.use(async (config) => {
      const now = Date.now();
      const expiresAt = auth.jwToken.expiresIn;
      const timeToExpiry = expiresAt - now;

      const barrierTime = process.env.REFRESH_TOKEN_TIME ? Number(process.env.REFRESH_TOKEN_TIME) : (30 * 60 * 1000);

      if (timeToExpiry < barrierTime) {
        await refreshToken();
        config.headers.Authorization = `Bearer ${auth.jwToken.jwToken}`;
      }

      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 403 || error.response?.status === 401) {
          restartAuth();
          navigate('/login', { replace: true });
        }
        return Promise.reject(error);
      }
    );

    return instance;
  };

  const refreshToken = async () => {
    if (!auth.jwToken.jwToken) {
      console.error('Token is missing. Cannot refresh.');
      return;
    }

    try {
      const response = await axios.post(`${base}/api/public/security/token-login?token=${auth.jwToken.jwToken}`);
      updateAuth(response.data);
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  };


  const apiInstances: ApiInstances = {
    apiParentPdfMealMenuController: (url: ApiParentPdfMealMenuController) => createApiInstance(`${base}/api/secured/parent-meal-menu${url ? `/${url}` : ''}`),
    apiParentMealToDayController: (url: ApiParentMealToDayControllerUrl) => createApiInstance(`${base}/api/secured/parent-meal-to-day${url ? `/${url}` : ''}`),
    apiTenantConsumerTransactionsController: (url: ApiTenantConsumerTransactionsControllerUrl) => createApiInstance(`${base}/api/secured/financial-operations${url ? `/${url}` : ''}`),
    apiOldAppImportController: (url: ApiOldAppImportControllerUrl) => createApiInstance(`${base}/api/secured/super-admin/old-app-import${url ? `/${url}` : ''}`),
    apiSuperAdminFixController: (url: ApiSuperAdminFixUrl) => createApiInstance(`${base}/api/secured/super-admin-fix${url ? `/${url}` : ''}`),
    apiParentConsumerController: (url: ApiParentConsumerControllerUrl) => createApiInstance(`${base}/api/secured/parent/children${url ? `/${url}` : ''}`),
    apiTenantTenantController: (url: ApiTenantTenantControllerUrl) => createApiInstance(`${base}/api/secured/tenant/tenant${url ? `/${url}` : ''}`),
    apiTenantPdfMealMenuController: (url: ApiTenantPdfMealMenuControllerUrl) => createApiInstance(`${base}/api/secured/tenant/pdf-meal-menu${url ? `/${url}` : ''}`),
    apiTenantCoFinancingInstitutionController: (url: ApiTenantCoFinancingInstitutionControllerUrl) => createApiInstance(`${base}/api/secured/tenant/cofinancing-institution${url ? `/${url}` : ''}`),
    apiTenantAdminBoughtStakesController: (url: ApiTenantAdminBoughtStakesControllerUrl) => createApiInstance(`${base}/api/secured/tenant-admin-bought-stakes${url ? `/${url}` : ''}`),
    apiTenantLogEntitiesController: (url: ApiTenantLogEntitiesController) => createApiInstance(`${base}/api/secured/super-admin/log-entries${url ? `/${url}` : ''}`),
    apiConsumerFinancialHistoryController: (url: ApiConsumerFinancialHistoryControllerUrl) => createApiInstance(`${base}/api/secured/consumer-history${url ? `/${url}` : ''}`),
    apiTenantConsumerController: (url: ApiTenantConsumerControllerUrl) => createApiInstance(`${base}/api/secured/tenant/children${url ? `/${url}` : ''}`),
    apiOrderHistoryController: (url: ApiOrderHistoryControllerUrl) => createApiInstance(`${base}/api/secured/order-history/${url ? `/${url}` : ''}`),
    apiTenantStakeController: (url: ApiTenantStakeControllerUrl) => createApiInstance(`${base}/api/secured/tenant/stakes${url ? `/${url}` : ''}`),
    apiTenantMealController: (url: ApiTenantMealControllerUrl) => createApiInstance(`${base}/api/secured/tenant/meals${url ? `/${url}` : ''}`),
    apiTenantParentImportController: (url: ApiTenantParentImportControllerUrl) => createApiInstance(`${base}/api/secured/tenant/parent-import${url ? `/${url}` : ''}`),
    apiTenantMealToDayController: (url: ApiTenantMealToDayControllerUrl) => createApiInstance(`${base}/api/secured/tenant/meal-to-day${url ? `/${url}` : ''}`),
    apiTenantMealMealController: (url: ApiTenantMealMealControllerUrl) => createApiInstance(`${base}/api/secured/tenant/meal-menu${url ? `/${url}` : ''}`),
    apiTenantCourseController: (url: ApiTenantCourseControllerUrl) => createApiInstance(`${base}/api/secured/tenant/course${url ? `/${url}` : ''}`),
    apiTenantUserController: (url: ApiTenantUserControllerUrl) => createApiInstance(`${base}/api/secured/tenant/users${url ? `/${url}` : ''}`),
    apiTenantSpecialConsumerController: (url: ApiTenantSpecialConsumerController) => createApiInstance(`${base}/api/secured/tenant/special-consumers${url ? `/${url}` : ''}`),
    apiTenantFreeWorkingDayController: (url: ApiTenantFreeWorkingDayControllerUrl) => createApiInstance(`${base}/api/secured/tenant/working-days${url ? `/${url}` : ''}`),
    apiTenantAllergenController: (url: ApiTenantAllergenControllerUrl) => createApiInstance(`${base}/api/secured/tenant/allergens${url ? `/${url}` : ''}`),
    apiTenantMealDistributionController: (url: ApiTenantMealDistributionControllerUrl) => createApiInstance(`${base}/api/secured/tenant/meal-distribution${url ? `/${url}` : ''}`),
    apiTenantAdminController: (url: ApiTenantAdminControllerUrl) => createApiInstance(`${base}/api/secured/tenant/admins${url ? `/${url}` : ''}`),
    apiTenantOrganisationUnitController: (url: ApiTenantOrganisationUnitControllerUrl) => createApiInstance(`${base}/api/secured/tenant/organisation-unit${url ? `/${url}` : ''}`),
    apiTenantController: (url: ApiTenantControllerUrl) => createApiInstance(`${base}/api/secured/super-admin/tenants${url ? `/${url}` : ''}`),
    apiCompanyController: (url: ApiCompanyControllerUrl) => createApiInstance(`${base}/api/secured/super-admin/companies${url ? `/${url}` : ''}`),
    apiAbsenceAndCourseController: (url: ApiAbsenceAndCourseControllerUrl) => createApiInstance(`${base}/api/secured/absences${url ? `/${url}` : ''}`),
    apiPublicSecurityController: (url: ApiPublicSecurityControllerUrl) => createApiInstance(`${base}/api/public/security${url ? `/${url}` : ''}`),
    apiTenantParentController: (url: ApiTenantParentControllerUrl) => createApiInstance(`${base}/api/secured/tenant/parents${url ? `/${url}` : ''}`),
    apiMassOrderController: (url: ApiMassOrderControllerUrl) => createApiInstance(`${base}/api/secured/mass-order${url ? `/${url}` : ''}`),
    apiParentDashboardContextController: (url: ApiParentDashboardContextControllerUrl) => createApiInstance(`${base}/api/secured/parent-dashboard/context${url ? `/${url}` : ''}`),
    apiParentPaySaldoController: (url: ApiParentSaldoControllerUrl) => createApiInstance(`${base}/api/secured/parent-saldo-payment/context${url ? `/${url}` : ''}`),
    apiOrderController: (url: ApiOrderControllerUrl) => createApiInstance(`${base}/api/secured/order${url ? `/${url}` : ''}`),
    apiParentPaymentController: (url: ApiParentPaymentControllerUrl) => createApiInstance(`${base}/api/secured/parent-payment${url ? `/${url}` : ''}`),
    apiTenantStatuteController: (url: ApiTenantStatuteControllerUrl) => createApiInstance(`${base}/api/secured/tenant/statute${url ? `/${url}` : ''}`),
    apiPrivateSecurityController: (url: ApiPrivateSecurityControllerUrl) => createApiInstance(`${base}/api/secured/security${url ? `/${url}` : ''}`),
    apiParentPaymentSimulationController: (url: ApiParentPaymentSimulationControllerUrl) => createApiInstance(`${base}/api/secured/parent-payment-simulator${url ? `/${url}` : ''}`),
    apiParentDataController: (url: ApiParentDataControllerUrl) => createApiInstance(`${base}/api/secured/parent-data${url ? `/${url}` : ''}`),
    apiTenantAdminPaymentController: (url: ApiTenantAdminPaymentControllerUrl) => createApiInstance(`${base}/api/secured/tenant-admin-payment${url ? `/${url}` : ''}`),
    apiParentFinancialHistoryController: (url: ApiParentFinancialHistoryControllerUrl) => createApiInstance(`${base}/api/secured/parent-finance${url ? `/${url}` : ''}`),
    apiParentMessageController: (url: ApiParentMessageControllerUrl) => createApiInstance(`${base}/api/secured/parent/messages${url ? `/${url}` : ''}`),
    apiTenantMessageController: (url: ApiTenantMessageControllerUrl) => createApiInstance(`${base}/api/secured/tenant/messages${url ? `/${url}` : ''}`),
    apiReportController: (url: ApiReportControllerUrl) => createApiInstance(`${base}/api/secured/tenant/reports${url ? `/${url}` : ''}`),
    apiParentRegistrationController: (url: ApiParentRegistrationControllerUrl) => createApiInstance(`${base}/api/public/parent-registration${url ? `/${url}` : ''}`),
    apiCaptchaController: (url: ApiCaptchaController) => createApiInstance(`${base}/api/public/captcha${url ? `/${url}` : ''}`),
    apiAdminDataControllerUrl: (url: ApiAdminDatController) => createApiInstance(`${base}/api/secured/admin-data${url ? `/${url}` : ''}`)
  }

  return (
    <ApiContext.Provider value={apiInstances}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi musi być używane wewnątrz ApiProvider');
  }
  return context;
};
