import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal
} from '@chakra-ui/react';
import axios, { HttpStatusCode } from "axios";
import React, { useState } from "react";
import { useAlertContext } from "../../../../context/AlertProvider";
import { useApi } from '../../../../context/ApiProvider';
import { NewCompany } from '../../../../shared/type/company.type';
import handlePhoneNumberChange from "../../../../utils/handlePhoneNumberChange";
import handlePostCodeChange from '../../../../utils/handlePostCodeChange';
import FormInput from '../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const defaultBranch: NewCompany = {
  name: '',
  street: '',
  city: '',
  postCode: '',
  email: '',
  phone: '',
  bankAccountNumber: '',
  adminEmail: '',
  adminFirstName: '',
  adminLastName: ''
};

const AddNewCompany = ({ isOpen, onClose }: Props) => {
  const { apiCompanyController } = useApi();
  const { setAlertProperties, setShow } = useAlertContext()
  const [newCompany, setNewCompany] = useState<NewCompany>(defaultBranch);

  const handleAddNewCompany = async (e: React.FormEvent) => {
   e.preventDefault();
    try {
      const response = await apiCompanyController('').post('', newCompany);
      if(response.status === HttpStatusCode.Ok){
        setNewCompany(defaultBranch);
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.message);
        if(error.response && error.response.status === HttpStatusCode.BadRequest){
          setAlertProperties({
            timeout: 5000,
            title: `Błąd pola ${error.response?.data.violations[0].fieldName}`,
            description: `Podpowiedź: ${error.response?.data.violations[0].message}`,
            status: 'warning',
          })
          setShow(true)
        } else if (error.response) {
          console.error('Response status:', error.response.status);
          console.error('Response data:', error.response.data);
          setAlertProperties({
            title: 'Uwaga',
            description: `${error.response.data.errorMessage ? error.response.data.errorMessage : 'Błąd formularza'}`,
            status: 'warning',
            timeout: 5000
          });
          setShow(true);
        } else if (error.request) {
          console.error('Request data:', error.request);
        }
      } else {
        console.error('Unexpected error:', error);
      }
    } finally {
      // setNewCompany(defaultBranch);
      // onClose();
    }
  };

  const handleClose = () => {
    setNewCompany(defaultBranch);
    onClose();
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewCompany((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={handleAddNewCompany}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dodaj firmę</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-xl">
            <FormInput
              label="Nazwa firmy"
              placeholder="Wpisz nazwę firmy"
              inputParams={{
                value: newCompany?.name,
                name: 'name',
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Numer konta bankowego"
              placeholder="Wpisz numer konta bankowego"
              inputParams={{
                value: newCompany?.bankAccountNumber,
                name: 'bankAccountNumber',
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Email"
              placeholder="Wpisz email firmy"
              inputParams={{
                type: 'email',
                value: newCompany?.email,
                name: 'email',
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Telefon"
              placeholder="Wpisz numer telefonu"
              inputParams={{
                type: 'tel',
                maxLength: 11,
                minLength: 11,
                value: newCompany?.phone,
                name: 'phone',
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  updateState(e)},
                required: true
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Adres firmy
            </div>
            <FormInput
              label="Ulica"
              placeholder="Wpisz nazwę ulicy, numer i numer lokalu"
              inputParams={{
                value: newCompany?.street,
                name: 'street',
                onChange: updateState,
                required: true
              }}
            />
            <div className="flex justify-between">
              <FormInput
                label="Kod pocztowy"
                placeholder="00-000"
                inputParams={{
                  maxLength: 6,
                  minLength: 6,
                  value: newCompany?.postCode,
                  name: 'postCode',
                  onChange: (e) => {
                    handlePostCodeChange(e, setNewCompany);
                  },
                  required: true,
                  pattern: '[0-9]{2}-[0-9]{3}'
                }}
              />
              <FormInput
                label="Miasto"
                placeholder="Wybierz"
                inputParams={{
                  value: newCompany?.city,
                  name: 'city',
                  onChange: updateState,
                  required: true
                }}
              />
            </div>
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Administrator firmy
            </div>
            <FormInput
              label="Imię"
              placeholder="Wpisz imię administratora"
              inputParams={{
                value: newCompany?.adminFirstName,
                name: 'adminFirstName',
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Nazwisko"
              placeholder="Wpisz nazwisko administratora"
              inputParams={{
                value: newCompany?.adminLastName,
                name: 'adminLastName',
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Adres email"
              placeholder="Wpisz adres email administratora"
              inputParams={{
                type: 'email',
                value: newCompany?.adminEmail,
                name: 'adminEmail',
                onChange: updateState,
                required: true
              }}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between">
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleClose}
              backgroundColor="#FFFFFF"
              className="grow"
            >
              Zamknij
            </Button>
            <Button variant="ghost" type="submit" className="grow" backgroundColor="#FFDB94">
              Dodaj
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddNewCompany;
