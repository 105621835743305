import { TimeInterface } from "../shared/type/time.interface";

const hours = (): TimeInterface[] => {
  const hoursTable = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 5) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const numValue = hour * 100 + minute;

      hoursTable.push({
        value: `${formattedHour}:${formattedMinute}`,
        label: `${formattedHour}:${formattedMinute}`,
        numValue: numValue,
      });
    }
  }

  return hoursTable;
};

export default hours;
