import { useState, useEffect } from "react";
import calculatePrice from "../../../../../../../../../utils/calculators/calculatePrice";

interface Props {
  consumer: { consumerName: string; currentSaldo: number; consumerId: number };
  updateConsumerAmount: (consumerId: number, amount: string) => void;
}

const TenantAdminSaldoUserCard = ({ consumer, updateConsumerAmount }: Props) => {
  const { consumerName, currentSaldo, consumerId } = consumer;
  const [moneyAmount, setMoneyAmount] = useState("0.00");
  const [calculation, setCalculation] = useState(currentSaldo);

  const validateAndFormatAmount = (value: string) =>
    value.replace(/[^0-9.]/g, "").replace(/^(\d*\.\d{0,2}|\d*).*/, "$1");

  useEffect(() => {
    const timeout = setTimeout(
      () => setCalculation(currentSaldo + parseFloat(moneyAmount) * 100 || 0),
      200
    );
    return () => clearTimeout(timeout);
  }, [moneyAmount]);

  return (
    <div className="flex flex-col gap-spacing-xl w-full">
      <p className="text-lg text-grayLight-900 font-semibold">{consumerName}</p>
      <div className="flex flex-col gap-spacing-xl">
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Saldo:</label>
          <p className="text-lg text-grayLight-700 font-normal">{calculatePrice(currentSaldo,'zł')}</p>
        </div>
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Wpłacam:</label>
          <input
            onChange={(e) => {
              const formattedValue = validateAndFormatAmount(e.target.value);
              setMoneyAmount(formattedValue);
              updateConsumerAmount(consumerId, formattedValue);
              setCalculation(currentSaldo + parseFloat(moneyAmount) * 100 || 0);
            }}
            value={moneyAmount}
            type="text"
            className="rounded-md border box-border w-24"
            style={{ padding: "10px 14px" }}
          />
        </div>
        <div className="border-b border-grayLight-300 w-full" />
        <div className="flex justify-between items-center w-52">
          <label className="text-sm text-grayLight-700 font-medium">Saldo po wpłacie:</label>
          <p className="text-lg text-grayLight-700 font-normal">{calculatePrice(calculation, "zł")}</p>
        </div>
      </div>
    </div>
  );
};

export default TenantAdminSaldoUserCard;