import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select, { components } from "react-select";
import { useApi } from "../../../../../../context/ApiProvider";
import {
  EditMealDistributionInterface,
} from "../../../../../../shared/type/mealDistribution.type";
import { TimeInterface } from "../../../../../../shared/type/time.interface";
import hours from "../../../../../../utils/hours";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import FormInput from "../../../../../Forms/FormInput/FormInput";
import IconComponent from "../../../../../IconComponent/IconComponent";

type EditAddDistributionInterface = EditMealDistributionInterface;

interface Props {
  mode: 'add' | 'edit';
  isOpen: boolean;
  editedServing?: EditAddDistributionInterface
  onClose: () => void;
}

const defaultServing: EditAddDistributionInterface = {
  id: 0,
  tenantId: 0,
  name: '',
  startHour: '08:00',
  endHour: '08:15'
};

const AddEditServing = ({ mode, isOpen, editedServing, onClose }: Props) => {
  const { apiTenantMealDistributionController } = useApi();
  const { id: tenant } = useParams();
  const [ serving, setServing ] = useState<EditAddDistributionInterface>(defaultServing);
  const [time, setTime] = useState<TimeInterface[]>([]);
  const [ startTimeHelper, setStartTimeHelper ] = useState<number>(0);
  const [ endTimeHelper, setEndTimeHelper ] = useState<number>(9999);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '8px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      padding: '4px' +
        '',
      borderColor: '#ccc',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#aaa',
      },
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FFF7E6' : 'white',
      color: '#101828',
      padding: '8px',
      borderRadius: '8px',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      fontFamily: 'Arial, sans-serif',
      fontSize: '16px',
      color: '#333',
    }),
  };


  const { Option } = components;

  const IconOption = (props: any) => {
    const { data, isSelected } = props;
    return (
      <Option {...props}>
        <div className="flex justify-between items-center">
          <p className="text-base font-medium text-grayLight-900">{data.label}</p>
          {isSelected && <IconComponent iconName="check" color="#FFAC05" />}
        </div>
      </Option>
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const body = {
      tenantId: tenant,
      name: serving.name,
      startHour: serving.startHour,
      endHour: serving.endHour,
    };

    console.log(body)

   try{
      if(mode === 'edit'){
        await apiTenantMealDistributionController('').put(`/${serving.id}`, body);
      } else {
        await apiTenantMealDistributionController('').post('',body);
      }
   } catch (error) {
     console.error(error)
   } finally {
      onClose();
   }
  }

  useEffect(() => {
    const timeTable = hours();
    setTime(timeTable);
    if(mode === 'edit' && editedServing){
      setServing(editedServing)
    } else {
      setServing(defaultServing)
    }
  }, [mode, isOpen]);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={handleSubmit}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">{mode === 'add' ? 'Dodaj' : 'Edytuj'} wydawkę</h2>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label="Nazwa"
              placeholder="Wpisz nazwę wydawki, np. Śniadanie"
              inputParams={{
                required: true,
                name: 'name',
                minLength: 2,
                maxLength: 70,
                value: serving.name,
                onChange: (e) => {
                  setServing((prev) => ({ ...prev, name: e.target.value }));
                }
              }}
            />
            <div className="flex justify-between gap-spacing-xl">
              <div className="flex flex-col gap-1.5 basis-1/2">
                <label className="text-sm font-medium text-grayLight-600">Początek</label>
                <Select
                  required
                  options={time?.filter(option => option.numValue < endTimeHelper)}
                  name="transferMode"
                  placeholder='np. 08:00'
                  styles={customStyles}
                  className="basis-2/4"
                  value={time?.find(option => option.value === serving.startHour)}
                  onChange={(value) => {
                    setStartTimeHelper(value?.numValue || 0)
                    setServing((prev) => ({...prev, startHour: value ? value.value : ''}))}
                  }
                  components={{
                    Option: IconOption,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
              <div className="flex flex-col gap-1.5 basis-1/2">
                <label className="text-sm font-medium text-grayLight-600">Koniec</label>
                <Select
                  required
                  options={time?.filter(option => option.numValue > startTimeHelper)}
                  name="transferMode"
                  placeholder='np. 08:15'
                  styles={customStyles}
                  className="basis-2/4"
                  value={time?.find(option => option.value === serving.endHour)}
                  onChange={(value) => {
                    setEndTimeHelper(value?.numValue || 9999)
                    setServing((prev) => ({...prev, endHour: value ? value.value : ''}))}
                }
                  components={{
                    Option: IconOption,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null
                }}
                />
              </div>
            </div>
            <hr />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: "button",
                onClick: onClose
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}
            >
              {mode === 'add' ? 'Dodaj' : 'Zapisz'}
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditServing;