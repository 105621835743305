import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../../../../context/ApiProvider";
import { useEditAdministratorContext } from "../../../../../../../context/EditAdministratorProvider";
import { AvailableTenantInterface } from "../../../../../../../shared/type/tenant.type";
import CustomChakraButton from "../../../../../../CustomChakraButton/CustomChakraButton";
import CheckboxInput from "../../../../../../Forms/CheckboxInput/CheckboxInput";
import FormInput from "../../../../../../Forms/FormInput/FormInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AssignNewBranch = ({ isOpen, onClose }: Props) => {
  const { administrator, setAdministrator } = useEditAdministratorContext();
  const { id: tenant } = useParams();
  const { apiTenantTenantController } = useApi();
  const [searchedValue, setSearchedValue] = useState<string>('');
  const [availableSchools, setAvailableSchools] = useState<AvailableTenantInterface[]>([]);
  const [filteredSchools, setFilteredSchools] = useState<AvailableTenantInterface[]>([]);
  const [selectedTenants, setSelectedTenants] = useState<number[]>([]);

  const handleClose = () => {
    onClose();
  };

  const fetchAvailableSchools = async () => {
    try {
      const response: AxiosResponse<AvailableTenantInterface[]> = await apiTenantTenantController('list-available-tenants').get(``, {
        params: {
          tenantId: tenant
        }
      });
      const assignedIds: number[] = administrator.assignedTenants.map(tenant => tenant.id);
      const unassignedSchools = response.data.filter(item => !assignedIds.includes(item.id));
      setAvailableSchools(unassignedSchools);
      setFilteredSchools(unassignedSchools);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchedValue(value);
    const filtered = availableSchools.filter(school =>
      school.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSchools(filtered);
  };

  const handleCheckboxChange = (tenantId: number, isChecked: boolean) => {
    setSelectedTenants((prev) =>
      isChecked ? [...prev, tenantId] : prev.filter(id => id !== tenantId)
    );
  };

  const handleAssignNewTenant = () => {
    setAdministrator((prev) => ({
      ...prev,
      assignedTenants: [
        ...prev.assignedTenants,
        ...availableSchools.filter(school => selectedTenants.includes(school.id))
      ]
    }));
    handleClose();
  };

  useEffect(() => {
    fetchAvailableSchools();
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={() => {}}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col">
            <h2 className="font-semibold text-lg">Przypisz oddział</h2>
            <p className="font-normal text-sm">
              Wybierz oddział lub oddziały, którymi może zarządzać administrator {administrator.firstName} {administrator.lastName}.
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <FormInput
              label=""
              placeholder="Wyszukaj"
              inputParams={{
                onChange: handleSearchChange,
                value: searchedValue
              }}
            />
            <div className='w-full h-full'>
              {filteredSchools.map(school => (
                <CheckboxInput
                  key={school.id}
                  text={school.name}
                  textSize='lg'
                  checkboxPosition='items-center'
                  checkboxParams={{
                    onChange: (e) => handleCheckboxChange(school.id, e.target.checked),
                  }}
                />
              ))}
              {filteredSchools.length === 0 && <p className='font-normal text-lg'>Brak oddziałów do wyświetlenia</p>}
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                onClick: handleClose,
              }}
            >
              Zamknij
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "grow",
                type: 'submit',
                onClick: () => {
                    handleAssignNewTenant()
                  },
              }}
            >
              Dodaj
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default AssignNewBranch;
