import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTenantAdminBasketContext } from "../../../../../../../../context/TenantAdminBasketProvider";
import CustomChakraButton from "../../../../../../../CustomChakraButton/CustomChakraButton";

import CompleteCashOrder from "./CompleteCashOrder/CompleteCashOrder";
import AdminCashOrderSummary from "./AdminCashOrderSummary/AdminCashOrderSummary";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type StepType = 'order' | 'summary';

const SetOrderOnCash = ({ isOpen, onClose }: Props) => {
  const { placeOrderForPayment, setOrderType, isEmpty, resetBasket, calculatedPayment } = useTenantAdminBasketContext();
  const [ step, setStep ] = useState<StepType>('order')

  const handleButton = () => {
    if(step === 'order') {
      placeOrderForPayment()
      setStep('summary');
    }
  }

  const getStep = (step: StepType) => {
    switch (step) {
      case 'order':
        return  <CompleteCashOrder/>
      case "summary":
        return  <AdminCashOrderSummary onClose={onClose}/>
    }
  }

  useEffect(() => {
    setOrderType('cash')
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => {
      resetBasket()
      onClose()
    }} isCentered>
      <form onSubmit={() => {}}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent
          sx={{
            width: "calc(100vw - 100px)",
            height: "calc(100vh - 100px)",
            margin: "50px",
            maxWidth: "none",
            maxHeight: "none",
            backgroundColor: "#F7FAFC",
          }}
        >
          <ModalHeader className="flex">
          </ModalHeader>
          <ModalCloseButton />
          {getStep(step)}
          {step === 'order' && (<ModalFooter className="w-full flex justify-between gap-spacing-lg">
            {!isEmpty && (
              <div className="Basket__summary flex-col justify-end pr-spacing-xl">
                <div className="flex flex-col items-end gap-spacing-xxs">
                  <div className="flex gap-spacing-md items-center">
                    <p className='text-grayLight-800 text-sm font-semibold'>Wartość zamówienia:</p>
                    <p className='text-grayLight-800 text-2xl font-semibold'>{calculatedPayment?.totalOrderValue || "0.00"} zł</p>
                  </div>
                </div>
                <div className='flex' style={{ width: '500px' }}>
                  <CustomChakraButton
                    buttonProps={{
                      onClick: handleButton,
                      type: "button",
                      className: "grow",
                    }}
                    size="2xl"
                    hierarchy="primary"
                    iconPosition="onlyText">
                    Przejdź do podsumowania
                  </CustomChakraButton>
                </div>
              </div>
            )}
          </ModalFooter>)}
        </ModalContent>
      </form>
    </Modal>
  );
};

export default SetOrderOnCash;
