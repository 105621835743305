import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input
} from "@chakra-ui/react";
import Select from "react-select";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../../context/AlertProvider";
import { useApi } from "../../../../../../context/ApiProvider";
import { AllergenInterface } from "../../../../../../shared/type/allergen.type";
import { SimpleCourseInterface } from "../../../../../../shared/type/course.type";
import { NewMealInterface } from "../../../../../../shared/type/meal.type";
import { MealWithCategoryInterface, MealInterface } from "../../../../../../shared/type/mealsMenu.type";
import BadgeWithMessage from "../../../../../BadgeWithMessage/BadgeWithMessage";
import CustomChakraButton from "../../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../../CustomInput/CustomInput";
import IconComponent from "../../../../../IconComponent/IconComponent";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  mode: 'add' | 'edit';
  initData?: number;
}

const AddEditMealPlan = ({ isOpen, onClose, mode, initData }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantCourseController, apiTenantAllergenController, apiTenantMealController, apiTenantMealToDayController } = useApi();
  const { id: tenantId } = useParams();
  const [mealPlanDate, setMealPlanDate] = useState<string>('');

  const defaultMeal: NewMealInterface = {
    tenantId: Number(tenantId),
    when: mealPlanDate,
    courseId: 0,
    name: '',
    description: '',
    allergenIds: []
  };

  const defaultMealWithCategory: MealWithCategoryInterface = {
    id: 0,
    name: '',
    description: '',
    allergens: [],
    categoryId: 0
  };

  const [mealCategories, setMealCategories] = useState<SimpleCourseInterface[]>([]);
  const [allergens, setAllergens] = useState<AllergenInterface[]>([]);
  const [meals, setMeals] = useState<MealInterface[]>([])

  const [currentMeal, setCurrentMeal] = useState<MealWithCategoryInterface>(defaultMealWithCategory);
  const [assignedMeals, setAssignedMeals] = useState<MealWithCategoryInterface[]>([]);
  const [newMeals, setNewMeals] = useState<NewMealInterface[]>([]);
  const [newMeal, setNewMeal] = useState<NewMealInterface>(defaultMeal);

  const fetchMealCategories = async () => {
    try {
      const response: AxiosResponse<SimpleCourseInterface[]> = await apiTenantCourseController('simple-list').get(`/${tenantId}`);
      setMealCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllergens = async () => {
    try {
      const response: AxiosResponse<AllergenInterface[]> = await apiTenantAllergenController('').get(`/${tenantId}`);
      setAllergens(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMeals = async () => {
    try {
      const response: AxiosResponse<MealInterface[]> =  await apiTenantMealController('by-tenant').get(`/${tenantId}`);
      setMeals(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  const addNewMealToMealList = async () => {
    const newMealsCopy = [...newMeals];
    newMealsCopy.push(newMeal);
    setNewMeals(newMealsCopy);
    setNewMeal(defaultMeal)
  };

  useEffect(() => {
    fetchAllergens();
    fetchMealCategories();
    fetchMeals();
  }, [tenantId]);

  const getCategoryName = (categoryId: number) => {
    const category = mealCategories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Unknown Category';
  };

  const mergedMeals = [
    ...assignedMeals,
    ...newMeals.map((meal) => ({
      ...meal,
      id: meal.name + meal.courseId,
      categoryId: meal.courseId,
      allergens: meal.allergenIds.map((id) => allergens.find((allergen) => allergen.id === id)).filter(Boolean) as AllergenInterface[],
    })),
  ];

  const uniqueCategories = Array.from(new Set(mergedMeals.map(meal => meal.categoryId)));

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedMeal, setSelectedMeal] = useState<MealWithCategoryInterface | null>(null);

  const handleAddMealToAssignedMeals = () => {
    if (selectedMeal && selectedCategory) {
      setAssignedMeals((prev) => [
        ...prev,
        {
          ...selectedMeal,
          categoryId: selectedCategory,
        },
      ]);
      setSelectedCategory(null);
      setSelectedMeal(null);
      setSelectedMeal(defaultMealWithCategory);
    }
  };


  const handleDeleteMeal = (mealId: number | string) => {
    setAssignedMeals((prev) => prev.filter(meal => meal.id !== mealId));
    setNewMeals((prev) => prev.filter(meal => (meal.name + meal.courseId) !== mealId));
  };

  const handleSubmitMealPlan = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let newMealFlag = true;
    let mealFromBaseFlag = true;

    try {
      // First, add new meals and make assign
      try {
        for (const meal of newMeals) {
          const response = await apiTenantMealToDayController('create-assignment-with-meal').post('', {
            tenantId: Number(tenantId),
            when: mealPlanDate,
            courseId: meal.courseId,
            name: meal.name,
            description: meal.description,
            allergenIds: meal.allergenIds
          });
          if(response.status === HttpStatusCode.Ok){
            setAlertProperties({
              timeout: 2000,
              title: "Sukces",
              description: `Poprawnie dodano danie`,
              status: "success"
            });
            setShow(true);
          }
        }
      } catch (error) {
        newMealFlag = false;
        console.error("Error adding new meals:", error);
        throw error; // Re-throw to handle in outer catch
      }

      // Then, assign meal from base
      try {
        const dataObject = assignedMeals.map(meal => ({
          tenantId: Number(tenantId),
          mealId: meal.id,
          courseId: meal.categoryId,
          when: mealPlanDate
        }))
        const response = await apiTenantMealToDayController('create-assignments').post('', dataObject);
        if(response.status === HttpStatusCode.Ok){
          setAlertProperties({
            timeout: 2000,
            title: "Sukces",
            description: `Poprawnie dodano danie`,
            status: "success"
          });
          setShow(true);
        }
      } catch (error) {
        mealFromBaseFlag = false;
        console.error("Error assigning meals from base:", error);
      }
    } catch (error: any) {
      console.error("Error handling meal plan submission:", error);
      setAlertProperties({
        timeout: 5000,
        title: "Błąd",
        description: `Błąd dodawania: ${error}`,
        status: "error"
      });
    } finally {
      if (newMealFlag && mealFromBaseFlag) {
        setAlertProperties({
          timeout: 5000,
          title: "Sukces",
          description: "Pomyślnie dodano jadłospis",
          status: "success"
        });
        setShow(true);
        onClose();
      } else if (!newMealFlag && !mealFromBaseFlag) {
        setAlertProperties({
          timeout: 5000,
          title: "Błąd",
          description: "Nie udało się dodać ani nowych posiłków, ani przypisać posiłków z bazy",
          status: "error"
        });
        setShow(true);
      } else if (!newMealFlag) {
        setAlertProperties({
          timeout: 5000,
          title: "Częściowy sukces",
          description: "Nie udało się dodać nowych posiłków, ale posiłki z bazy zostały przypisane",
          status: "warning"
        });
      } else if (!mealFromBaseFlag) {
        setAlertProperties({
          timeout: 5000,
          title: "Częściowy sukces",
          description: "Nowe posiłki zostały dodane, ale nie udało się przypisać posiłków z bazy",
          status: "warning"
        });
        setShow(true);
      }
    }
  };


  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="70-vw" isCentered>
      <form onSubmit={handleSubmitMealPlan}>
        <ModalOverlay
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          }}
        />
        <ModalContent>
          <ModalHeader className="flex">
            <div className='basis-5/12 flex flex-col text-grayLight-900 font-semibold text-lg border-r border-grayLight-200'>
              <h2 className="font-semibold text-lg">Dodaj do jadłospisu</h2>
              <p className='text-grayLight-600 text-sm font-normal'>Aby stworzyć jadłospis wybierz datę a następnie dodaj kategorie i przypisz do nich dania.</p>
            </div>
            <div className='basis-7/12 flex items-center justify-between text-grayLight-700 font-semibold text-xl box-border border-l border-grayLight-200'>
              <h2 className="font-semibold text-lg pr-spacing-3xl pl-spacing-3xl">Podgląd do jadłospisu</h2>
              <h2 className="font-semibold text-lg pr-spacing-3xl pl-spacing-3xl">{mealPlanDate || 'Data'}</h2>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="flex">
            <div className="basis-5/12 border-r border-grayLight-200 flex flex-col gap-spacing-xl pr-spacing-3xl pl-spacing-3xl">
              <div className="flex flex-col gap-spacing-xs">
                <label className="text-sm font-medium text-grayLight-700">Dzień obowiązywania jadłospisu</label>
                <div className='w-44'>
                  <Input
                    placeholder="Wybierz datę"
                    type="date"
                    lang="pl"
                    required
                    onChange={(e) => { setMealPlanDate(e.target.value) }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-spacing-xl">
                <div className="flex flex-col pt-spacing-xs pb-spacing-xs border-b border-brand-600">
                  <p className="font-semibold text-lg text-grayLight-900">Stwórz danie</p>
                </div>
                <div className="flex flex-col gap-spacing-xl">
                  <div className="flex flex-col">
                    <label className="text-sm font-medium text-grayLight-700">Przypisz kategorię</label>
                    <Select
                      colorScheme="gray"
                      required
                      placeholder="Wybierz"
                      onChange={(value) => {
                        setNewMeal((prev) => ({ ...prev, courseId: Number(value?.value) }));
                      }}
                      options={mealCategories.map((unit) => ({
                        label: unit.name,
                        value: unit.id,
                        variant: "outline"
                      }))}
                    />
                  </div>
                  <CustomInput type="default" size="md" destructive={false} label="Nazwa*" inputParams={{
                    value: newMeal.name,
                    onChange: (e) => {
                      setNewMeal((prev) => ({ ...prev, name: e.target.value }));
                    },
                    placeholder: "Wpisz nazwę dania np. Owsianka z owocami"
                  }} />
                  <div className="flex flex-col">
                    <label className="text-grayLight-700 text-sm">Opis</label>
                    <textarea cols={30}
                              rows={5}
                              style={{ resize: "none" }}
                              value={newMeal.description}
                              onChange={(e) => {
                                setNewMeal((prev) => ({ ...prev, description: e.target.value }));
                              }}
                              maxLength={1000}
                              className="border border-gray-300 rounded-lg p-spacing-lg" />
                    <div className="flex justify-between">
                      <label className="text-grayLight-700 text-sm">maks. 1000 znaków</label>
                      <label className="text-grayLight-700 text-sm">{newMeal.description.length}/1000</label>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm font-medium text-grayLight-700">Alergeny</label>
                    <Select
                      colorScheme="gray"
                      isMulti
                      required
                      placeholder="Wybierz"
                      onChange={(value) => {
                        setNewMeal((prev) => ({ ...prev, allergenIds: value.map((unit) => Number(unit.value)) }));
                      }}
                      options={allergens.map((unit) => ({
                        label: unit.name,
                        value: unit.id,
                        variant: "outline"
                      }))}
                    />
                  </div>
                  <div className="flex justify-end">
                    <CustomChakraButton
                      buttonProps={{
                        type: "button",
                        disabled: newMeal.name.length === 0,
                        onClick: addNewMealToMealList
                      }}
                      hierarchy="secondaryColor"
                      size="sm"
                      iconPosition="left"
                      icon="plus"
                    >Dodaj do jadłospisu</CustomChakraButton>
                  </div>
                </div>
                <div className="flex flex-col pt-spacing-xs pb-spacing-xs border-b border-brand-600">
                  <p className="font-semibold text-lg text-grayLight-900">Dodaj dania z bazy dań</p>
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-grayLight-700">Wybierz kategorię dania</label>
                  <Select
                    colorScheme="gray"
                    placeholder="Wybierz kategorię"
                    value={selectedCategory ? { label: getCategoryName(selectedCategory), value: selectedCategory } : null}
                    onChange={(value) => {
                      setSelectedCategory(Number(value?.value));
                      setCurrentMeal((prev) => ({ ...prev, categoryId: Number(value?.value) }));
                    }}
                    options={mealCategories.map((category) => ({
                      label: category.name,
                      value: category.id,
                      variant: "outline"
                    }))}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-sm font-medium text-grayLight-700">Wybierz danie</label>
                  <Select
                    colorScheme="gray"
                    isSearchable={true}
                    placeholder="Wybierz posiłek"
                    value={selectedMeal ? { label: selectedMeal.name, value: selectedMeal.id } : null}
                    onChange={(value) => {
                      const currentMeal = meals.find(meal => Number(meal.id) === value?.value);
                      setSelectedMeal(currentMeal ? {
                        categoryId: selectedCategory!,
                        id: currentMeal.id,
                        name: currentMeal.name,
                        description: currentMeal.description,
                        allergens: currentMeal.allergens
                      } : null);
                    }}
                    options={meals
                      .map((meal) => ({
                        label: meal.name,
                        value: meal.id,
                        variant: "outline"
                      }))}
                  />
                </div>
                <div className="flex justify-end">
                  <CustomChakraButton
                    hierarchy="secondaryColor"
                    size="sm"
                    iconPosition="left"
                    icon="plus"
                    buttonProps={{
                      type: "button",
                      disabled: !selectedCategory || !selectedMeal,
                      onClick: () => {
                        handleAddMealToAssignedMeals();
                        console.log(mergedMeals)
                      }
                    }}
                  >Dodaj do jadłospisu</CustomChakraButton>
                </div>
              </div>
            </div>
            <div
              className={`basis-7/12 border-l border-grayLight-200 flex flex-col ${mergedMeals.length === 0 ? " items-center justify-center" : ""}`}>
              {mergedMeals.length === 0 ? (
                <div className="flex flex-col items-center justify-center gap-spacing-xl">
                  <div>
                    <IconComponent iconName="cutlery" className="w-10 h-10" />
                  </div>
                  <p>Tu pojawi się lista dań dodanych do jadłospisu na dany dzień.</p>
                </div>
              ) : (
                <div className="p-spacing-3xl">
                  <ul className='list-disc ml-5 flex flex-col gap-spacing-4xl'>
                    {uniqueCategories.map(categoryId => (
                      <li key={categoryId} className="text-lg font-semibold list-none text-grayLight-900">
                        <h3 className="text-lg font-semibold">{getCategoryName(categoryId).charAt(0).toUpperCase() + getCategoryName(categoryId).slice(1)}</h3>
                        <ul className='flex flex-col gap-spacing-4xl'>
                          {mergedMeals.filter(meal => meal.categoryId === categoryId).map(meal => (
                            <li key={meal.id} className='text-sm font-medium text-grayLight-700'>
                              <div className='flex flex-col gap-spacing-xs'>
                                <div className="flex justify-between border-b border-brand-300">
                                  <p className="font-normal text-sm text-grayLight-900 pt-spacing-xs pr-spacing-md pb-spacing-xs pl-spacing-md">{meal.name}</p>
                                  <button className='bg-transparent border-none' onClick={() => handleDeleteMeal(meal.id)}>
                                    <IconComponent iconName='xClose' className='w-3 h-3' />
                                  </button>
                                </div>
                                <p
                                  className="text-grayLight-600 pt-spacing-xs pr-spacing-md pb-spacing-xs pl-spacing-md">{meal.description}</p>
                                <div className='flex gap-spacing-xs flex-wrap'>
                                  {meal.allergens.map(allergen => (
                                    <BadgeWithMessage tooltipLabel={allergen.name} key={allergen.id}>{`${allergen.formalNumber}`}</BadgeWithMessage>
                                  ))}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: "basis-5/12",
                disabled: mergedMeals.length === 0 || mealPlanDate.length === 0,
                type: "button",
                onClick: handleSubmitMealPlan
              }}
            >
              Zakończ
            </CustomChakraButton>
            <div className='basis-7/12'>

            </div>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddEditMealPlan;
