import { HttpStatusCode } from "axios";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from '../../../context/ApiProvider';
import FormInput from '../../Forms/FormInput/FormInput';
import { AuthInterface } from '../../../shared';
import useAuth from '../../../hooks/useAuth';
import IconComponent from "../../IconComponent/IconComponent";

import './Login.css';

const Login = () => {
  const authContext = useAuth();
  const navigate = useNavigate();
  const { apiPublicSecurityController, apiCaptchaController } = useApi();

  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const activationHash = searchParams.get("activationHash");

  const [formEmail, setFormEmail] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formPassword, setFormPassword] = useState<string>('');
  const [isCaptcha, setIsCaptcha] = useState<boolean>(false);
  const [captchaImage, setCaptchaImage] = useState<string>("");
  const [captchaValue, setCaptchaValue] = useState<string>("");

  const [invalidDataResponse, setInvalidDataResponse] = React.useState<{
    status: boolean;
    message: string | null;
  }>({
    status: false,
    message: ''
  });

  const fetchCaptchaImage = async () => {
    try {
      const response = await apiCaptchaController("image").get("", {
        responseType: "arraybuffer",
      });

      // Convert arraybuffer to Base64
      const base64Image = btoa(
        new Uint8Array(response.data)
          .reduce((data, byte) => data + String.fromCharCode(byte), "")
      );

      setCaptchaImage(`data:image/jpeg;base64,${base64Image}`);
    } catch (error) {
      console.error("Error fetching captcha image:", error);
    }
  };

  const updateAuthContext = (authContext: any, data: AuthInterface) => {
    const {
      loggedUserDto: {
        id,
        login,
        firstName,
        lastName,
        availableTenantsIds,
        availableModules,
        roleName,
        admin,
        childOwner,
        tenantAdmin,
        superAdmin,
      },
      availableTenants,
      enforceEmailUpdate,
      jwToken: { jwToken, expiresIn },
    } = data;

    authContext.updateAuth({
      loggedUserDto: {
        id,
        login,
        firstName,
        lastName,
        availableTenantsIds,
        availableModules,
        roleName,
        admin,
        childOwner,
        tenantAdmin,
        superAdmin,
      },
      availableTenants,
      enforceEmailUpdate,
      jwToken: { jwToken, expiresIn },
    });

    window.localStorage.setItem("isLogged", "true");
    window.localStorage.setItem("persist", "true");
    window.localStorage.setItem("jwsToken", jwToken);
  };

  const handleApiRequest = async (
    apiFunction: () => Promise<any>,
    onSuccess: (data: AuthInterface) => void,
    onError: (message: string) => void
  ) => {
    try {
      const response = await apiFunction();
      if (response?.status === HttpStatusCode.Ok) {
        onSuccess(response.data);
      } else {
        onError("Nieoczekiwany status odpowiedzi.");
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        onError(error.response?.data.errorMessage || "Błędne dane.");
      } else {
        onError("Błąd połączenia z serwerem.");
      }
    }
  };


  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await handleApiRequest(
      () =>
        apiPublicSecurityController("login").post<AuthInterface>("", {
          login: formEmail,
          password: formPassword,
          captchaValue,
        }),
      (data) => updateAuthContext(authContext, data),
      (message) => {
        setInvalidDataResponse({ status: true, message });
        apiCaptchaController("is-captcha").get("").then((result) => {
          setIsCaptcha(result.data);
        });
      }
    );
  };

  const activateUser = async (email: string, activationHash: string) => {
    await handleApiRequest(
      () =>
        apiPublicSecurityController("activate-user").post<AuthInterface>("", null, {
          params: {
            email,
            "activation-hash": activationHash,
          }
        }),
      (data) => updateAuthContext(authContext, data),
      (message) => {
        setInvalidDataResponse({ status: true, message });
      }
    );
  };

    useEffect(() => {
      if (email && activationHash) {
        activateUser(email, activationHash);
      }
    }, [email, activationHash]);

  useEffect(() => {
    if (authContext.auth.loggedUserDto.superAdmin) {
      navigate('/superAdmin/branches');
    } else if (authContext.auth.loggedUserDto.admin) {
      navigate('/tenantAdmin/id/undefined/school/classes');
    } else if (authContext.auth.loggedUserDto.tenantAdmin) {
      navigate('/tenantAdmin/id/undefined/school/classes');
    } else if (authContext.auth.loggedUserDto.childOwner) {
      navigate('/parent/main');
    } else {
      navigate('/login');
    }
  }, [authContext.auth, navigate]);

  useEffect(() => {
    if (isCaptcha) fetchCaptchaImage();
  }, [isCaptcha]);

  return (
    <div className="LoginPage__login-form w-full flex flex-col justify-between items-start">
      <div className="grid gap-8 m-auto w-screen lg:w-full lg:px-0 px-container-padding-mobile">
        <div className="LoginPage__header grid gap-spacing-lg">
          <h2 className="font-semibold text-4xl text-grayLight-900">Zaloguj się</h2>
          <p className="font-normal text-base text-grayLight-600">
            Zamawiaj szkolne posiłki bez wychodzenia z domu!
          </p>
        </div>
        <form className="max-w-2xl grid gap-spacing-3xl w-full" onSubmit={loginUser}>
          <div className='flex flex-col gap-spacing-md'>
            <div className='flex flex-col gap-spacing-2xl'>
              <div className='w-full'>
                <FormInput
                  label="Email"
                  inputParams={{
                    value: formEmail,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormEmail(e.target.value);
                    },
                    placeholder: 'Wpisz adres email lub otrzymany login',
                    type: 'text',
                    required: true
                  }}
                />
              </div>
              <div className="w-full relative">
                <FormInput
                  label="Hasło"
                  inputParams={{
                    value: formPassword,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormPassword(e.target.value);
                    },
                    placeholder: "Wpisz hasło",
                    minLength: 8,
                    type: showPassword ? "text" : "password",
                    required: true
                  }}
                  state={invalidDataResponse.status ? "error" : "normal"}
                  bottomElement={
                    invalidDataResponse.status && invalidDataResponse.message ? (
                      <p className="whitespace-normal text-sm text-error-600 dark:text-grayDark-200">
                        {invalidDataResponse.message}
                      </p>
                    ) : (
                      <p className="whitespace-normal text-sm text-grayLight-600 dark:text-grayDark-200">
                        Hasło musi zawierać minimum 8 znaków, jeden znak specjalny i jedną cyfrę
                      </p>
                    )
                  }
                />

                {/* Absolutne pozycjonowanie przy prawej krawędzi, centrowanie w pionie */}
                <div
                  className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    setShowPassword((prevState) => !prevState);
                  }}
                >
                  <IconComponent
                    color="#98A2B3"
                    iconName={showPassword ? "hidePassword" : "showPassword"}
                    className="h-4.5 w-4.5"
                  />
                </div>
              </div>
            </div>
            {captchaImage && (
              <div className="my-4 text-center">
                <img
                  src={captchaImage}
                  alt="Captcha"
                  style={{ maxWidth: "100%", maxHeight: "300px", margin: "0 auto" }}
                />
                <button
                  type="button"
                  onClick={fetchCaptchaImage}
                  className="mt-2 bg-gray-200 hover:bg-gray-300 text-sm font-semibold py-1 px-3 rounded"
                >
                  Odśwież obrazek
                </button>
              </div>
            )}
            {captchaImage && (
              <div className="w-full">
                <FormInput
                  label="Przepisz tekst z obrazka"
                  inputParams={{
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      setCaptchaValue(e.target.value);
                    },
                    placeholder: "Przepisz tekst z obrazka",
                    type: "text",
                    required: true
                  }}
                />
              </div>
            )}
            <div className="flex flex-row justify-end">
              <Link to="/login/recover">
                <p className="text-sm font-semibold cursor-pointer">Przypomnij hasło</p>
              </Link>
            </div>
          </div>
          {/* <div> */}
          {/*   <CheckboxInput text="Zapamiętaj mnie" /> */}
          {/* </div> */}
          <button
            type="submit"
            style={{
              borderRadius: "10px",
            }}
            className="bg-brand-300 shadow-custom border-brand-300 py-2.5 px-4 font-semibold text-base"
          >
            Zaloguj się
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
