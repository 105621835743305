export interface DayOffInterface extends Omit<NewDayOffInterface, 'tenantId' | 'organisationIds'> {
  id: number;
  organisationUnits: OrganisationUnit[];
}

export enum FreeDayType {
  FREE_DAY = 'FREE_DAY',
  WORKING_DAY = 'WORKING_DAY',
  SALES_BLOCKADE = 'SALES_BLOCKADE',
}

export interface NewDayOffInterface {
  tenantId: number;
  name: string;
  from: string;
  to: string;
  freeDayType: FreeDayType
  organisationIds: number[];
}

export interface OrganisationUnit {
  id: number;
  name: string;
  consumerCategory: string;
}
