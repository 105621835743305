import { TabList, Tab, Tabs } from "@chakra-ui/react";
import { useState, useEffect } from "react";

interface Props {
  data: {
    value: number;
    label: string;
  }[] | null | undefined;
  onChange: (value: number) => void;
}

const CustomHorizontalTabs = ({ data, onChange }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    if (data && data.length === 1) {
      onChange(data[0].value);
    } else if (data && data.length > 1) {
      onChange(data[0].value);
    }
  }, []);

  if (!data || data.length === 1) return null;

  const getSelectedStyle = (isMobile: boolean) => {
    return isMobile ? {
      color: '#B57A04',
      borderBottom: '2px solid #B57A04',
    } : {
      color: '#344054',
      bg: '#FFFFFF',
      borderRadius: '6px',
      borderWidth: '1px',
      boxShadow: '0px 1px 3px 0px #1018281A',
    }
  }

  return (
    <Tabs
      {...(!isMobile && { variant: "unstyled" })}
      index={selectedIndex}
      onChange={(index) => {
        setSelectedIndex(index);
        onChange(data[index].value);
      }}
    >
      <TabList className={` ${!isMobile && 'w-full flex rounded-xl box-border border border-grayLight-200 gap-spacing-xs p-spacing-xs bg-grayNeutral-50'}`}>
        {data.map((item) => (
          <Tab
            key={item.value}
            _hover={getSelectedStyle(isMobile)}
            _selected={getSelectedStyle(isMobile)}
            className={` ${!isMobile && 'w-full text-sm font-semibold text-grayLight-500 flex rounded-xl box-border border-grayLight-200 gap-spacing-xs p-spacing-sm bg-grayNeutral-50'}`
            }
          >
            {item.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

export default CustomHorizontalTabs;
