import { useState } from "react";
import { useParentDashboardContextContext } from "../../../../context/ParentDashboardContext";
import CustomChakraButton from "../../../CustomChakraButton/CustomChakraButton";
import IconComponent from "../../../IconComponent/IconComponent";
import ParentAddKidPopUp from "./ParentAddKidPopUp/ParentAddKidPopUp";

const AddKidNotification = () => {
  const [ showAddKidPopUp, setShowAddKidPopUp] = useState<boolean>(false);
  const [ showNotification, setShowNotification] = useState<boolean>(true);
  const { fetchParentDashboardContext } = useParentDashboardContextContext();

  return (
    <div className={`AddKidNotification bg-brand-100 border-brand-300 border box-border rounded-xl p-spacing-xl ${!showNotification && 'hidden'}`}>
      <div className='flex'>
        <div className='w-10'>
          <IconComponent iconName='info' color='#FFAC05' className='w-4 h-4'/>
        </div>
        <div className='flex flex-col grow gap-spacing-lg'>
          <div className='flex flex-col gap-spacing-xs'>
            <p className='text-sm font-semibold text-grayLight-900'>
              Uzupełnij profil
            </p>
            <p className='text-sm font-normal text-grayLight-700'>
              Aby w pełni korzystać z możliwości naszej platformy uzupełnij niezbędne dane w profilu dziecka
            </p>
          </div>
          <div>
            <CustomChakraButton
              size='sm'
              icon='plus'
              iconColor='#292524'
              iconPosition='left'
              hierarchy='primary'
              buttonProps={{
                onClick: () => {setShowAddKidPopUp(true)}
              }}
            >
              Dodaj profil dziecka
            </CustomChakraButton>
          </div>
        </div>
        <div className="">
          <button onClick={() => {setShowNotification(false)}}>
            <IconComponent iconName='xClose' className='w-2.5 h-2.5' color='#98A2B3'/>
          </button>
        </div>
      </div>
      {showAddKidPopUp && <ParentAddKidPopUp onClose={() => {
        setShowAddKidPopUp(false)
        fetchParentDashboardContext()
      }} isOpen={showAddKidPopUp}/>}
    </div>
  )
};

export default AddKidNotification;