import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useApi } from '../../../../context/ApiProvider';
import { NewTenantInterface } from '../../../../shared/type/tenant.type';
import handlePhoneNumberChange from '../../../../utils/handlePhoneNumberChange';
import handlePostCodeChange from '../../../../utils/handlePostCodeChange';
import FormInput from '../../../Forms/FormInput/FormInput';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  companyId?: string;
}

const defaultBranch: NewTenantInterface = {
  parentCompanyId: 1,
  name: '',
  shortName: '',
  urlPart: '',
  address: '',
  city: '',
  postCode: '',
  email: '',
  contactPerson: '',
  phone: '',
  supportPhone: '',
  supportEmail: ''
};

const AddNewBranch = ({ isOpen, onClose, companyId }: Props) => {
  const { apiTenantController } = useApi();
  const [newBranch, setNewBranch] = useState<NewTenantInterface>(defaultBranch);

  const handleAddNewBranch = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log(newBranch);
      await apiTenantController('').post('', {
        ...newBranch,
        parentCompanyId: companyId
      });
    } catch (error) {
      console.error(error);
    } finally {
      setNewBranch(defaultBranch);
      onClose();
    }
  };

  const handleClose = () => {
    setNewBranch(defaultBranch);
    onClose();
  };

  const updateState = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewBranch((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <form onSubmit={handleAddNewBranch}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dodaj oddział</ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-xl">
            <FormInput
              label="Nazwa"
              placeholder="Wpisz nazwę oddziału"
              inputParams={{
                value: newBranch?.name,
                name: 'name',
                maxLength: 255,
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Nazwa skrócona"
              placeholder="Wpisz skróconą nazwę"
              inputParams={{
                value: newBranch?.shortName,
                name: 'shortName',
                maxLength: 255,
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Identyfikator URL"
              placeholder="Wpisz url do rejestracji"
              inputParams={{
                value: newBranch?.urlPart,
                name: 'urlPart',
                maxLength: 100,
                onChange: updateState,
                required: true
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Adres oddziału
            </div>
            <FormInput
              label="Ulica"
              placeholder="Wpisz nazwę ulicy, numer i numer lokalu"
              inputParams={{
                value: newBranch?.address,
                name: 'address',
                maxLength: 255,
                onChange: updateState,
                required: true
              }}
            />
            <div className="flex justify-between">
              <FormInput
                label="Kod pocztowy"
                placeholder="00-000"
                inputParams={{
                  maxLength: 6,
                  minLength: 6,
                  value: newBranch?.postCode,
                  name: 'postCode',
                  onChange: (e) => {
                    handlePostCodeChange(e, setNewBranch);
                  },
                  required: true
                }}
              />
              <FormInput
                label="Miasto"
                placeholder="Wybierz"
                inputParams={{
                  value: newBranch?.city,
                  name: 'city',
                  maxLength: 50,
                  onChange: updateState,
                  required: true
                }}
              />
            </div>
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Osoba kontaktowa
            </div>
            <FormInput
              label="Imię i nazwisko"
              placeholder="Wpisz imię i nazwisko"
              inputParams={{
                value: newBranch?.contactPerson,
                name: 'contactPerson',
                maxLength: 100,
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Adres email"
              placeholder="Wpisz ades e-mail"
              inputParams={{
                type: 'email',
                value: newBranch?.email,
                name: 'email',
                maxLength: 100,
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Numer telefonu"
              placeholder="123-456-789"
              inputParams={{
                type: 'tel',
                maxLength: 11,
                minLength: 11,
                value: newBranch?.phone,
                name: 'phone',
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  updateState(e);
                },
                required: true
              }}
            />
            <div className="text-sm font-medium text-grayLight-500 border-b border-grayLight-200">
              Kontakt widoczny dla rodziców
            </div>
            <FormInput
              label="Adres email wsparcia"
              placeholder="Wpisz ades e-mail"
              inputParams={{
                type: 'email',
                value: newBranch?.supportEmail,
                name: 'supportEmail',
                maxLength: 100,
                onChange: updateState,
                required: true
              }}
            />
            <FormInput
              label="Numer telefonu wsparcia"
              placeholder="123-456-789"
              inputParams={{
                type: 'tel',
                maxLength: 11,
                minLength: 11,
                value: newBranch?.supportPhone,
                name: 'supportPhone',
                onChange: (e) => {
                  e.target.value = handlePhoneNumberChange(e.target.value);
                  updateState(e);
                },
                required: true
              }}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between">
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleClose}
              backgroundColor="#FFFFFF"
              borderColor="#D0D5DD"
              textColor="#344054"
              className="grow"
            >
              Zamknij
            </Button>
            <Button variant="ghost" type="submit" className="grow" backgroundColor="#FFDB94">
              Dodaj
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
};

export default AddNewBranch;
