import { TableContainer, Thead, Th, Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import { RateInterface, EditRateInterface } from "../../../../../shared/type/rate.type";
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import IconComponent from "../../../../IconComponent/IconComponent";
import AddEditRate from "./AddEditRate/AddEditRate";

const defaultDeleteElement = {
  id: 0,
  isOpen: false
}


export const defaultRate = {
  id: 0,
  active: true,
  tenantId: 0,
  name: '',
  price: '00,00',
  organisationUnitIds: [0],
  startDate: `${new Date().toLocaleDateString('en-CA')}`,
  endDate: `${new Date().toLocaleDateString('en-CA')}`,
  totalSalesLimit: null,
  salesLimitForPerson: null
}

const Rates = () => {
  const columnHelper = createColumnHelper<RateInterface>();
  const { apiTenantStakeController } = useApi();
  const { id: tenant } = useParams();
  const { setShow, setAlertProperties } = useAlertContext();
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ rates, setRates ] = useState<RateInterface[]>([])
  const [ deleteElement, setDeleteElement ] = useState(defaultDeleteElement)
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const [ editRate, setEditRate ] = useState<EditRateInterface>(defaultRate);
  const [ addEditRatePopUp, setAddEditRatePopUp ] = useState<{
    mode: 'add' | 'edit',
    isOpen: boolean
  }>({
    mode: 'add',
    isOpen: false,
  });

  const fetchRates = async () => {
    setIsLoading(true)
    try {
      const response = await apiTenantStakeController('').get(`/${tenant}`)
      setRates(response.data)
    } catch (error) {

    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const name = info.getValue();

        return(
          <p className='text-grayLight-900 font-medium text-sm flex flex-wrap'>
            {name}
          </p>
        )
      },
      header: 'Nazwa'
    }),
    columnHelper.accessor('price', {
      cell: (info) => {
        const price = info.getValue();

        return(
          <p className='text-grayLight-900 font-medium text-sm'>
            {price}
          </p>
        )
      },
      header: 'Cena'
    }),
    columnHelper.accessor('from', {
      cell: (info) => {
        const from = info.getValue();

        return(
          <p className='text-grayLight-900 font-normal text-sm'>
            {from}
          </p>
        )
      },
      header: 'Od'
    }),
    columnHelper.accessor('to', {
      cell: (info) => {
        const to = info.getValue();

        return(
          <p className="text-grayLight-900 font-normal text-sm">
            {to}
          </p>
        )
      },
      header: "Do"
    }),
    columnHelper.accessor('organisationUnits', {
      cell: (info) => {
        const units = info.getValue();

        return (
          <div className='w-24 flex flex-wrap'>
            {units.map((unit, index) => <p className='text-grayLight-600 font-semibold text-sm mr-2'>{unit.name}{index < units.length - 1 && ","}</p>)}
          </div>
        );
      },
      header: 'Klasy'
    }),
    columnHelper.accessor('active', {
      cell: (info) => {
        const active = info.getValue();
        return (
          <p className='text-grayLight-600 font-normal text-sm'>
            {active ? "Aktywny" : "Nieaktywny"}
          </p>)
      },
      header: 'Aktywny'
    }),
    columnHelper.accessor('totalSalesLimit', {
      cell: (info) => {
        const {totalSalesLimit, salesLimitForPerson} = info.row.original;
        return(
          <p className='text-grayLight-600 font-normal text-sm'>
            {totalSalesLimit === null ? '' : 'Dzienny placówki: '+totalSalesLimit}
            <br/>
            {salesLimitForPerson === null ? '' : 'Na osobę: ' + salesLimitForPerson}
          </p>
        )
      },
      header: 'Limity'
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Edycja',
      cell: (info) => {
        const {id, active} = info.row.original;

        return (
          <div className="flex gap-spacing-lg pt-spacing-xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl ">
            <button
              onClick={() => {

                if (active) {
                  setShowAlert(true);
                }

                if(!active){
                  setDeleteElement({isOpen: true, id: Number(id)})
                }

              }}
              className="font-semibold text-sm text-grayLight-600 bg-transparent border-none">
              Usuń
            </button>
            <button
              onClick={() => {
                const { name, price, from: startDate, to: endDate, totalSalesLimit, salesLimitForPerson, active, organisationUnits } = info.row.original;
                setEditRate({
                  id, name, price, startDate, endDate, active, totalSalesLimit, salesLimitForPerson, tenantId: Number(tenant), organisationUnitIds: organisationUnits.map(unit => unit.id),
                });
                setAddEditRatePopUp({
                  mode: 'edit',
                  isOpen: true
                })
              }}
              className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
              Edytuj
            </button>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchRates();
  }, [tenant]);

  return (
    <section className="Rates p-spacing-xl w-full">
      <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-11xl">
        <DataTable
          disableFilters
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5 gap-spacing-xxs">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base normal-case text-grayLight-900"
                      >
                        Stawki
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm normal-case text-grayLight-700"
                      >
                        Tutaj możesz tworzyć i edytować stawki jakie będą obowiązywać w oddziale. Stawki mogą zostać zakupione poprzez system transakcyjny przez rodziców za cenę jaka zostanie określona w systemie.
                        Stawka obowiązuje w konkretnym zakresie dat. Może mieć też zdefiniowany "limit dzienny na osobę" np. na poziomie 2 - będzie to oznaczać, że dana osoba może kupić maksymalnie 2 stawki na dany dzień. Można też zdefiniować dla stawki limit dla całej placówki np. 400, wówczas użytkownicy będą w stanie kupić łącznie maksimum 400 stawek danego typu.
                        Stawki mogą są przypisane do danych klas.
                      </Text>
                    </Box>
                  </div>
                  <CustomChakraButton size="md" hierarchy="primary" iconColor="#292524"  iconPosition="left" icon="plus" buttonProps={{
                    onClick: () => {
                      setAddEditRatePopUp({
                        mode: 'add',
                        isOpen: true
                      })
                    }
                  }}>
                    Dodaj stawkę
                  </CustomChakraButton>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={rates}
        />
      </TableContainer>
      {addEditRatePopUp.isOpen ? (
        <AddEditRate
          isOpen={addEditRatePopUp.isOpen}
          initData={editRate}
          mode={addEditRatePopUp.mode}
          onClose={() => {
            setAddEditRatePopUp({
              isOpen: false,
              mode: 'add',
            })
            fetchRates();
          }}
        />
      ) : null}
      <CustomAlert
        header="Nie można usunąć stawki"
        content="Stawka ma status aktywny i jest przypisana do wydawki."
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'primary'
        }}
        confirmButtonText="Zamknij"
        onConfirmAction={() => {
          setShowAlert(false)
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setShowAlert(false)
        }}
        handleOpen={showAlert}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-warning-100">
            <IconComponent iconName="info" color='#DC6803' />
          </div>
        }
      />
      <CustomAlert
        header="Usunąć stawkę?"
        content="Usunięcie stawki jest nieodwracalne."
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText="Usuń"
        onConfirmAction={async () => {
          try {
            const response = await apiTenantStakeController('').delete(`/${tenant}/${deleteElement.id}`);
            if (response.status === HttpStatusCode.Ok) {
              setAlertProperties({
                timeout: 2000,
                title: "Sukces",
                description: `Stawka została usunięta`,
                status: "success"
              });
              setShow(true);
            }
            // fetchRates();
          } catch (error) {
            setAlertProperties({
              timeout: 2000,
              title: "Błąd",
              description: `Nie udało się usunąć stawki`,
              status: "error"
            });
            setShow(true);
          }
        }}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText="Anuluj"
        onCancelAction={() => {
          setDeleteElement(defaultDeleteElement);
          fetchRates();
        }}
        handleOpen={deleteElement.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
      />
    </section>
  );
};

export default Rates;
