import { Checkbox, Divider, TableContainer} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import React, { useState, useEffect } from "react";
import { useApi } from "../../../context/ApiProvider";
import { useParentDashboardContextContext } from "../../../context/ParentDashboardContext";
import {
  ParentMealToDayInterface,
  MealToDayDayInterface
} from "../../../shared/type/mealToDay.type";
import BadgeWithMessage from "../../BadgeWithMessage/BadgeWithMessage";
import ChooseMonths from "../../ChooseMonths/ChooseMonths";
import CustomHorizontalTabs from "../../CustomHorizontalTabs/CustomHorizontalTabs";
import { DataTable } from "../../DataTable/DataTable";
import IconComponent from "../../IconComponent/IconComponent";

const ParentMealPlan = () => {
  const today = new Date();
  const { apiParentMealToDayController, apiParentPdfMealMenuController } = useApi();
  const { parentDashboardContext } = useParentDashboardContextContext()

  const [ mealPlans, setMealPlans] = useState<MealToDayDayInterface[]>([]);
  const [mealPlanDate, setMealPlanDate] = useState<{ year: number; month: number, day: number }>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });
  const columnHelper = createColumnHelper<MealToDayDayInterface>();

  const [ selectedTenant, setSelectedTenant ] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchMealPlans = async () => {
    try {
      setIsLoading(true);
      if(Number(selectedTenant) === 0) return
      const response: AxiosResponse<ParentMealToDayInterface> = await apiParentMealToDayController('').post('', {
        tenantId: Number(selectedTenant),
        year: mealPlanDate.year,
        month: mealPlanDate.month,
      });
      setMealPlans(response.data.days);
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadMealPlanPdf = async (pdfId: number) => {
    try {
      const response = await apiParentPdfMealMenuController('').get(`/${selectedTenant}/${pdfId}/export`, {
        responseType: 'blob',
      });

      if (response.data) {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        window.open(pdfUrl, '_blank');

      }
    } catch (error: any) {
      console.error('Error downloading PDF:', error);
    }
  };

  const columns = [
    columnHelper.display({
      id: 'select',
      header: () => <Checkbox colorScheme="customOrange" />,
      cell: () => <Checkbox colorScheme="customOrange" />,
    }),
    columnHelper.accessor('when', {
      cell: (info) => {
        const { when } = info.row.original;
        const date = new Date(when);

        const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
        const dayName = date.toLocaleDateString('pl-PL', options);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');

        return (
          <div className="flex flex-col">
            <p className="text-grayLight-900 font-medium text-sm">{dayName}</p>
            <p className="text-grayLight-600 font-normal text-sm">{`${day}.${month}`}</p>
          </div>
        );
      },
      header: "Dzień",
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const { mealsToCourse, workingDay, pdfMenu } = info.row.original;

        if (!workingDay) {
          return <p>Wolne</p>;
        }

        if (pdfMenu !== null) {
          return <button className='flex gap-spacing-xs pt-2.5 pr-3 pb-2.5 pl-3' onClick={() => {
            downloadMealPlanPdf(pdfMenu?.id)
          }}>
            <IconComponent iconName='pdf'/>
            <p className='text-sm font-semibold text-grayWarm-950'>Zobacz jadłospis</p>
          </button>
        }

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}
          >
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`h-full text-grayLight-900 text-sm font-medium pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {meal.courseName.charAt(0).toUpperCase() + meal.courseName.slice(1)}
                </div>
              </React.Fragment>
            ))}
          </div>

        );
      },
      header: "Kategoria posiłków"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col h-full"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`flex-grow flex flex-col justify-between pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  <p className="text-grayLight-600 text-sm font-medium">{meal.mealDto?.name || "Brak posiłku"}</p>
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: "Danie"
    }),
    columnHelper.accessor("mealsToCourse", {
      cell: (info) => {
        const mealsToCourse = info.getValue();

        return (
          <div
            className="flex flex-col"
            style={{ height: 60 * (mealsToCourse?.length || 0) + 'px'}}>
            {mealsToCourse?.map((meal, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider backgroundColor="#EAECF0" color="#EAECF0" />}
                <div
                  style={{ height: 1 / (mealsToCourse?.length || 0) * 100 + '%'}}
                  className={`basis-1/${mealsToCourse?.length} text-grayLight-600 flex-wrap flex text-sm font-medium gap-spacing-md pt-spacing-xl pr-spacing-md pb-spacing-xl pl-spacing-md`}>
                  {Number(meal.mealDto?.allergens.length) > 0 ? meal.mealDto?.allergens.map(allergen => (
                    <BadgeWithMessage tooltipLabel={<p
                      className="rounded-lg pt-spacing-md pr-spacing-lg pb-spacing-md pl-spacing-lg text-sm text-grayLight-900 font-medium">{allergen.name}</p>}>{`${allergen.formalNumber}`}</BadgeWithMessage>
                  )) : 'Brak alergenów'}
                </div>
              </React.Fragment>
            ))}
          </div>
        );
      },
      header: 'Alergeny',
    })
  ];

  useEffect(() => {
    if(Number(selectedTenant) !== 0){
      fetchMealPlans();
    }
  }, [ selectedTenant, mealPlanDate ]);

  return (
    <section className="ParentMealPlan flex flex-col bg-grayLight-50 overflow-y-auto">
      <div className="pt-spacing-3xl pr-spacing-3xl pb-spacing-xl pl-spacing-3xl">
        <div className="flex justify-between">
          <div className='flex flex-col'>
            <h1 className="p-0 m-0 font-semibold text-grayLight-900" style={{ fontSize: "30px" }}>
              Jadłospis
            </h1>
            <p className='font-normal text-grayLight-600' style={{ fontSize: "16px" }}>
              Zobacz jadłospis na najbliższe dni / najbliższy miesiąc
            </p>
          </div>
          <div className='hidden lg:block'>
            <ChooseMonths setDate={setMealPlanDate} currentDate={mealPlanDate} mode='month' />
          </div>
        </div>
      </div>
      <div className='flex flex-col pt-spacing-xl pr-spacing-4xl pl-spacing-3xl pb-spacing-xl gap-spacing-xl'>
        <CustomHorizontalTabs
          onChange={(value) => {
            setSelectedTenant(value);
          }}
          data={parentDashboardContext ? parentDashboardContext.tenants.map(tenant => ({
            label: tenant.tenantName,
            value: tenant.tenantId,
          })) : null}
        />
      </div>
      <div className="ParentMealPlan__absenceTable hidden lg:flex flex-col pb-spacing-5xl"
           style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <TableContainer h="100%" flex="1" minH="500px" className="Branches__table bg-white rounded-lg pb-spacing-8xl">
          <DataTable
            disableFilters={true}
            columns={columns}
            isLoading={isLoading}
            data={mealPlans}
          />
        </TableContainer>
      </div>
      <div className="ParentMealPlan flex lg:hidden flex-col pb-spacing-5xl gap-spacing-xl"
           style={{ flexGrow: 1, overflowY: 'auto', marginBottom: '20px' }}>
        <div className='flex justify-center '>
          <ChooseMonths hideTodayButton={true} setDate={setMealPlanDate} currentDate={mealPlanDate} mode='month' />
        </div>
        <div className="flex flex-col">
          { mealPlans?.map((mealPlan) => {
            const {when} = mealPlan;
            const date = new Date(when);

            const options: Intl.DateTimeFormatOptions = { weekday: 'long' };
            const dayName = date.toLocaleDateString('pl-PL', options);
            const shortDate = `${date.getDate().toString().padStart(2,'0')}.${(date.getMonth()+1).toString().padStart(2,'0')}`;

            if(!mealPlan.workingDay){
              return (
                <div className="flex flex-col">
                  <div
                    className="flex gap-spacing-md py-spacing-md pr-spacing-md pl-spacing-xl border-b box-border border-grayLight-100">
                    <p className={
                      'text-sm font-medium text-grayLight-900'
                    }>{dayName}</p>
                    <p className={
                      'text-sm font-medium text-grayLight-600'
                    }>{shortDate}</p>
                  </div>
                </div>
              )
            }

            return (
              <div className="flex flex-col">
                <div
                  className="flex gap-spacing-md py-spacing-md pr-spacing-md pl-spacing-xl border-b box-border border-brand-300">
                  <p className={
                    "text-sm font-medium text-grayLight-900"
                  }>{dayName}</p>
                  <p className={
                    "text-sm font-medium text-grayLight-600"
                  }>{shortDate}</p>
                </div>
                <div className="flex py-spacing-md px-spacing-xl border-b box-border border-grayLight-200">
                  <div className="basis-10/12">
                    <p className="text-xs font-medium text-grayLight-600">Danie</p>
                  </div>
                  <div className="basis-2/12">
                    <p className='text-xs font-medium text-grayLight-600'>Alergeny</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  {mealPlan?.mealsToCourse?.map((meal) => (
                    <div className='flex border-b box-border border-grayLight-200'>
                      <div className='flex flex-col basis-10/12 py-spacing-md pl-spacing-xl pr-spacing-xl'>
                        <p className='py-spacing-md text-sm font-medium text-grayLight-900'>{meal.courseName}</p>
                        <div>
                          <p className='pt-spacing-md text-sm font-medium text-grayLight-900'>{meal.mealDto?.name}</p>
                          <p  className='pb-spacing-md text-sm font-normal text-grayLight-600'>{meal.mealDto?.description}</p>
                        </div>
                      </div>
                      <div className="basis-2/12 py-spacing-md">
                        {meal.mealDto?.allergens.map((allergen) => (
                         <div className='flex gap-spacing-xxs flex-wrap'>
                          <div className='basis-5/12 gap-spacing-xxs'>
                            <BadgeWithMessage tooltipLabel={<p
                              className="rounded-lg px-spacing-xl py-spacing-xxs text-xs text-grayLight-900 font-medium">{allergen.name}</p>}>{`${allergen.formalNumber}`}</BadgeWithMessage>
                          </div>
                         </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
};

export default ParentMealPlan