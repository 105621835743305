import { TableContainer, Thead, Th, Box, Text } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import useAuth from "../../../../../hooks/useAuth";
import { AdministratorInterface } from "../../../../../shared/type/administrator.type";
import { ModuleInterface, ModuleType } from "../../../../../shared/type/module.type";
import { AvailableTenantInterface } from "../../../../../shared/type/tenant.type";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import { DataTable } from "../../../../DataTable/DataTable";
import CustomAlert from "../../../../CustomAlert/CustomAlert";
import IconComponent from "../../../../IconComponent/IconComponent";
import AddAdministrator from "../AddAdministrator/AddAdministrator";

const defaultDeleteAdminPopUp = {
  isOpen: false,
  adminId: 0,
}

const defaultActivateAdminPopUp = {
  isOpen: false,
  adminId: 0,
}

const AdministratorsTable = () => {
  const columnHelper = createColumnHelper<AdministratorInterface>();
  const { setShow: setShowAlert, setAlertProperties } = useAlertContext();
  const { apiTenantAdminController, apiTenantUserController } = useApi();
  const { id: tenant } = useParams();
  const { auth } = useAuth();
  const location = useLocation();
  const [administrators, setAdministrators] = useState<AdministratorInterface[]>([]);
  const [modules, setModules] = useState<ModuleInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [deleteAdminPopUp, setDeleteAdminPopUp] = useState(defaultDeleteAdminPopUp);
  const [activateAdminPopUp, setActivateAdminPopUp] = useState(defaultActivateAdminPopUp);

  const fetchAdministrators = async () => {
    try {
      setIsLoading(true);
      const result = await apiTenantAdminController('list-admins-related-to-tenant').get('', auth.loggedUserDto.superAdmin ? {
        params: {
          tenantId: tenant
        }
      } : undefined);
      setAdministrators(result.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchAvailableModules = async () => {
    try {
      const result = await apiTenantAdminController('modules').get('');
      setModules(result.data);
    } catch (error) {
      console.error(error);
    }
  }

  const deleteAdministrator = async () => {
    try {
      await apiTenantAdminController('').delete(`/${deleteAdminPopUp.adminId}`);
      setAlertProperties({
        timeout: 2000,
        status: 'success',
        title: 'Sukces',
        description: 'Poprawnie usunięto administratora'
      })
    } catch (error) {
      setAlertProperties({
        timeout: 5000,
        status: 'error',
        title: 'Błąd',
        description: 'Błąd usuwania administratora'
      })
    } finally {
      fetchAdministrators();
      setShowAlert(true);
    }
  }

  const activateAdministrator = async () => {
    try {
      await apiTenantUserController('').post(`/activate/${activateAdminPopUp.adminId}`);
      setAlertProperties({
        timeout: 2000,
        status: 'success',
        title: 'Sukces',
        description: 'Poprawnie aktywowano administratora'
      })
    } catch (error) {
      setAlertProperties({
        timeout: 5000,
        status: 'error',
        title: 'Błąd',
        description: 'Błąd aktywacji administratora'
      })
    } finally {
      fetchAdministrators();
      setShowAlert(true);
    }
  }

  const columns = [
    columnHelper.accessor('firstName', {
      cell: (info) => info.getValue(),
      header: 'Imię'
    }),
    columnHelper.accessor('lastName', {
      cell: (info) => info.getValue(),
      header: 'Nazwisko'
    }),
    columnHelper.accessor('login', {
      cell: (info) => info.getValue(),
      header: 'Email'
    }),
    columnHelper.display({
          id: 'assignedTenants',
          header: 'oddziały',
          cell: (info) => {
            const { assignedTenants } = info.row.original;
            return (
              <ul>
                {assignedTenants.map((tenant: AvailableTenantInterface) => (
                  <li key={tenant.id} className='list-disc text-grayLight-600 text-sm font-normal leading-6'>
                    {tenant.name}
                  </li>
                ))}
              </ul>
            )
          }
        }),
    columnHelper.display({
      id: 'availableModules',
      header: 'Dostęp',
      cell: (info) => {
        const { availableModules } = info.row.original;
        return (
          <ul>
            {availableModules.map((module: ModuleType) => (
              <li key={module} className='list-disc text-grayLight-600 text-sm font-normal leading-6'>
                {modules.find((singleModule) => singleModule.name === module)?.displayName}
              </li>
            ))}
          </ul>
        )
      }
    }),
    columnHelper.display({
      id: "actions",
      header: "",
      cell: (info) => {
        const admin = info.row.original;
        const newPath = location.pathname.replace('administrators-table', `single-administrator/${admin.id}/administrator-rights`);
        return (
          <div className="flex gap-spacing-sm">
            {!admin.mainCompanyAdmin &&
              <button
                type='button'
                className="font-semibold text-sm text-grayLight-600 bg-transparent border-none"
                onClick={() => {
                setDeleteAdminPopUp({
                  isOpen: true,
                  adminId: admin.id
                });
              }}>
                Usuń
              </button>}
            {!admin.active &&
              <button className="font-semibold text-sm text-grayLight-600 bg-transparent border-none" onClick={() => {
                setActivateAdminPopUp({
                  isOpen: true,
                  adminId: admin.id
                });
              }}>
                Aktywuj
              </button>}
            <Link to={newPath}>
              <button className="font-semibold text-sm text-grayWarm-950 bg-transparent border-none">
                Edytuj
              </button>
            </Link>
          </div>
        );
      }
    })
  ];

  useEffect(() => {
    fetchAdministrators();
    fetchAvailableModules();
  }, [tenant]);

  return (
    <section className='Administrators p-spacing-xl w-full'>
      <TableContainer h="100%" flex="1" minH="500px">
        <DataTable
          extraThead={
            <Thead>
              <Th colSpan={columns.length}>
                <div className="w-full flex items-center justify-between">
                  <div className="flex flex-col basis-4/5">
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-semibold text-base text-grayLight-900"
                      >
                        Lista administratorów
                      </Text>
                    </Box>
                    <Box>
                      <Text
                        whiteSpace="normal"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        className="font-normal text-sm text-grayLight-700"
                      >
                        Tutaj możesz edytować dane administratorów i ich uprawnienia w oddziale.
                      </Text>
                    </Box>
                  </div>
                  <div className='flex flex-row items-end gap-spacing-md'>
                    <CustomChakraButton size="md" hierarchy="primary" iconPosition="left" icon="plus" buttonProps={{
                      onClick: () => { setIsAddModalOpen(true) }
                    }}>
                      Dodaj administratora
                    </CustomChakraButton>
                  </div>
                </div>
              </Th>
            </Thead>
          }
          columns={columns}
          isLoading={isLoading}
          data={administrators}
        />
      </TableContainer>
      {isAddModalOpen && (
        <AddAdministrator
          onClose={() => {
            setIsAddModalOpen(false);
            fetchAdministrators();
          }}
          isOpen={isAddModalOpen}
        />
      )}
      <CustomAlert
        handleOpen={deleteAdminPopUp.isOpen}
        icon={
          <div className="w-12 h-12 flex items-center justify-center rounded-full bg-error-100">
            <IconComponent iconName="trash" />
          </div>
        }
        header={"Usunąć konto Administratora?"}
        content={"Usunięcie konta administratora spowoduje nieodwracalną utratę danych związanych z tym kontem."}
        cancelButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'secondaryGray'
        }}
        cancelButtonText={'Anuluj'}
        confirmButton={{
          iconPosition: 'onlyText',
          size: 'lg',
          hierarchy: 'warning'
        }}
        confirmButtonText={'Usuń'}
        onConfirmAction={() => {
          deleteAdministrator();
          setDeleteAdminPopUp(defaultDeleteAdminPopUp);
        }}
        onCancelAction={() => {
          fetchAdministrators();
          setDeleteAdminPopUp(defaultDeleteAdminPopUp)
        }} />
        <CustomAlert
          handleOpen={activateAdminPopUp.isOpen}
          icon={
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-brand-100">
              <IconComponent iconName="question" />
            </div>
          }
          header={"Aktywować Administratora?"}
          content={"Aktywacja pozwoli administratorowi się logować bez konieczności skorzystatania z linku aktywacyjnego."}
          cancelButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'secondaryGray'
          }}
          cancelButtonText={'Anuluj'}
          confirmButton={{
            iconPosition: 'onlyText',
            size: 'lg',
            hierarchy: 'primary'
          }}
          confirmButtonText={'Aktywuj'}
          onConfirmAction={() => {
            activateAdministrator();
            setActivateAdminPopUp(defaultActivateAdminPopUp);
          }}
          onCancelAction={() => {
            setActivateAdminPopUp(defaultActivateAdminPopUp)
          }} />
    </section>
  )
};

export default AdministratorsTable;
