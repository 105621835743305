import { HttpStatusCode, AxiosResponse } from "axios";
import React, { ReactNode, useState, createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AdministratorInterface, UpdateAdministratorInterface } from "../shared/type/administrator.type";
import { ModuleInterface } from "../shared/type/module.type";
import { useAlertContext } from "./AlertProvider";
import { useApi } from "./ApiProvider";

interface Props {
  children: ReactNode;
}

interface EditButtonStateInterface {
  isTopButtonActive: boolean,
  isBottomButtonActive: boolean,
}

interface EditAdministratorContextProps {
  fetchAdministrators: () => void;
  updateEmailPassword: () => void;
  editButtonState: EditButtonStateInterface;
  setEditButtonState: React.Dispatch<React.SetStateAction<EditButtonStateInterface>>;
  administrator: AdministratorInterface;
  setAdministrator: React.Dispatch<React.SetStateAction<AdministratorInterface>>;
  assignedModules: ModuleInterface[];
  setAssignedModules: React.Dispatch<React.SetStateAction<ModuleInterface[]>>;
  unassignTenant: (id: number) => Promise<void>;
  id: number;
  upgradeAdmin: () => void;
  setId: React.Dispatch<React.SetStateAction<number>>;
}

const defaultAdministrator: AdministratorInterface = {
  id: 0,
  firstName: '',
  lastName: '',
  login: '',
  phone: null,
  password: '',
  roleName: 'PARENT',
  assignedTenants: [],
  availableModules: [],
  active: false,
  archived: false,
  mainCompanyAdmin: false
}

const EditAdministratorContext = createContext<EditAdministratorContextProps | undefined>(undefined);

export const useEditAdministratorContext = () => {
  const context = useContext(EditAdministratorContext);
  if (!context) {
    throw new Error('useEditAdministratorContext must be used within a EditAdministratorProvider');
  }
  return context;
};

export const EditAdministratorProvider = ({ children }: Props) => {
  const { setShow, setAlertProperties } = useAlertContext();
  const { apiTenantAdminController, apiTenantUserController } = useApi();
  const { auth } = useAuth();
  const { id: tenant } = useParams();
  const [ modules, setModules ] = useState<ModuleInterface[]>([]);
  const [ administrator, setAdministrator] = useState<AdministratorInterface>(defaultAdministrator);
  const [ assignedModules, setAssignedModules ] = useState<ModuleInterface[]>([]);
  const [ editButtonState, setEditButtonState ] = useState<EditButtonStateInterface>({
    isTopButtonActive: false,
    isBottomButtonActive: false,
  })

  const [id, setId] = useState<number>(0);

  const fetchAdministrators = async () => {
    try {
      const result = await apiTenantAdminController('list-admins-related-to-tenant').get('', auth.loggedUserDto.superAdmin ? ({
        params: {
          tenantId: tenant
        }
      }) : undefined);
      const resultAdmin = result.data.find((admin: any) => admin.id === id)
      setAdministrator(resultAdmin);
    } catch (error) {
      console.error(error);
      throw new Error(`${error}`)
    } finally {
      fetchAvailableModules();
    }
  };

  const updateEmailPassword = async () => {
    try {
      await apiTenantUserController('email-or-password-update').put('', {
        tenantId: tenant,
        userId: administrator.id,
        email: administrator.login,
        newPassword: administrator.password
      })
      setAlertProperties({
        timeout: 2000,
        title: "Sukces",
        description: `Poprawnie zaktualizowano ustawienia administratora`,
        status: "success"
      });
      setEditButtonState((prev => ({...prev, isBottomButtonActive: false})));
    } catch (error: any) {
      const { status, data } = error.response;
      if(status === HttpStatusCode.Conflict) {
        setAlertProperties({
          timeout: 2000,
          title: "Niepowodzenie",
          description: `Administrator o podanym adresie email już istnieje w systemie.`,
          status: "warning"
        });
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: (
            <div className='flex flex-col'>
              <p>Błąd aktualizacji ustawień administratora:</p>
              <ul>
                {data.violations.map((item : any) => <li key={item.message}>{item.message}</li>)}
              </ul>
            </div>
          ),
          status: "error"
        });
      }
    } finally {
      setShow(true);
    }
  }

  const fetchAvailableModules = async () => {
    try {
      const result: AxiosResponse<ModuleInterface[]> = await apiTenantAdminController('modules').get('');
      setModules(result.data);

      const userAssignedModules = modules?.filter((module) =>
        administrator.availableModules.includes(module.name)
      );

      userAssignedModules && setAssignedModules(userAssignedModules);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const unassignTenant = async (tenantId: number) => {
    try {
      const newTenantTable = administrator.assignedTenants.filter((tenant) => Number(tenant.id) !== Number(tenantId))

      setAdministrator((prev) => ({
        ...prev,
        assignedTenants: newTenantTable,
      }));

      const userAssignedModules = modules?.filter((module) =>
        administrator.availableModules.includes(module.name)
      );

      userAssignedModules && setAssignedModules(userAssignedModules);
      upgradeAdmin(undefined , newTenantTable.map(tenant => tenant.id))
    } catch (error: any) {

    } finally {

    }
  }

  const upgradeAdmin = async (e?:  React.SyntheticEvent, newTenantsIds?: number[]) => {
    e?.preventDefault();

    try {
      const updatedAdmin: UpdateAdministratorInterface = {
        id: administrator.id,
        tenantIds: newTenantsIds ? newTenantsIds : administrator.assignedTenants.map(tenant => tenant.id),
        moduleIds: assignedModules.map(module => module.id),
        firstName: administrator.firstName,
        lastName: administrator.lastName,
        phone: administrator.phone
      }
      const {status} = await apiTenantAdminController('').put(`/${updatedAdmin.id}`, updatedAdmin);
      if(status === HttpStatusCode.Ok){
        setAlertProperties({
          timeout: 2000,
          title: "Sukces",
          description: `Poprawnie zaktualizowano ustawienia administratora`,
          status: "success"
        });
        setShow(true);
      } else {
        setAlertProperties({
          timeout: 2000,
          title: "Błąd",
          description: `Błąd aktualizacji ustawień administratora`,
          status: "error"
        });
        setShow(true);
      }
    } catch (error) {
      console.error(error);
      setAlertProperties({
        timeout: 2000,
        title: "Błąd",
        description: `Błąd aktualizacji ustawień administratora`,
        status: "error"
      });
      setShow(true);
    } finally {
      fetchAdministrators();
      setEditButtonState((prev => ({...prev, isTopButtonActive: false})))
    }
  }

  useEffect(() => {
    fetchAdministrators();
  }, [id]);

  const contextValue = useMemo(
    () => ({
      updateEmailPassword,
      fetchAdministrators,
      administrator,
      setAdministrator,
      assignedModules,
      setAssignedModules,
      editButtonState,
      setEditButtonState,
      unassignTenant,
      id,
      upgradeAdmin,
      setId
    }),
    [
      updateEmailPassword,
      fetchAdministrators,
      administrator,
      setAdministrator,
      assignedModules,
      setAssignedModules,
      editButtonState,
      setEditButtonState,
      unassignTenant,
      id,
      upgradeAdmin,
      setId
    ]
  );

  return (
    <EditAdministratorContext.Provider value={contextValue}>
      <form onSubmit={upgradeAdmin}>
        {children}
      </form>
    </EditAdministratorContext.Provider>
  );
};
