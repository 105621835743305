import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter
} from "@chakra-ui/react";
import { AxiosResponse, HttpStatusCode } from "axios";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useAlertContext } from "../../../../../context/AlertProvider";
import { useApi } from "../../../../../context/ApiProvider";
import useAuth from "../../../../../hooks/useAuth";
import { CustomConsumerInterface } from "../../../../../shared/type/consumerCreationContext.type";
import { NewKidInterface } from "../../../../../shared/type/kid.type";
import CustomChakraButton from "../../../../CustomChakraButton/CustomChakraButton";
import CustomInput from "../../../../CustomInput/CustomInput";
import IconComponent from "../../../../IconComponent/IconComponent";

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    padding: '4px' +
      '',
    borderColor: '#ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#aaa',
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#FFF7E6' : 'white',
    color: '#101828',
    padding: '8px',
    borderRadius: '8px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '16px',
    color: '#333',
  }),
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ParentAddKidPopUp = ({isOpen, onClose }: Props) => {
  const { apiParentConsumerController } = useApi();
  const { auth } = useAuth();
  const { setShow, setAlertProperties } = useAlertContext();
  const [ consumerCreationContext, setConsumerCreationContext] = useState<CustomConsumerInterface>();
  const [ chosenTenantId, setChosenTenantId ] = useState<number | undefined>(undefined);
  const [ newKid, setNewKid ] = useState<NewKidInterface>({
    tenantId: 1,
    firstName: '',
    lastName: '',
    organisationUnitId: null,
    cardNumber: '',
    remarks: '',
    parentIds: [
      auth.loggedUserDto.id
    ]
  })

  const fetchCreateConsumerContext = async () => {
    try {
      const response: AxiosResponse<CustomConsumerInterface> = await apiParentConsumerController('consumer-creation-context').get(`/${chosenTenantId}`);
      setConsumerCreationContext(response.data);


    } catch (error: any) {

    }
  };

  const addKid = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setNewKid((prev) => ({...prev, tenantId: chosenTenantId || 0}));

    try {
      const response = await  apiParentConsumerController('create-consumer').post('', newKid)

      if(response.status === HttpStatusCode.Ok) {
        setAlertProperties({
          timeout: 2000,
          title: "Poprawnie dodano dziecko",
          description: undefined,
          status: "success",
        })

        onClose();
      }
    } catch (error: any) {
    if (error.response.data.errorMessage){
          setAlertProperties({
            timeout: 5000,
            title: "Błąd dodawania dzieci",
            description: `Błąd: ${error.response.data.errorMessage}`,
            status: "error",
          })
    }else {
      const { message, fieldName } = error.response.data.violations[0]
      setAlertProperties({
        timeout: 5000,
        title: "Błąd dodawania dzieci",
        description: `Pole ${fieldName} - ${message}`,
        status: "error",
      })
      }
    } finally {
      setShow(true);
    }
  }

  useEffect(() => {
    fetchCreateConsumerContext();
  }, [chosenTenantId]);

  useEffect(() => {
    fetchCreateConsumerContext();

    const tenantsTable = auth.availableTenants;

    if(tenantsTable.length === 1) {
      setNewKid((prev) => ({...prev, tenantId: tenantsTable[0].id}))
      setChosenTenantId(tenantsTable[0].id)
    }
  }, []);

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <form onSubmit={addKid}>
        <ModalOverlay
          sx={{
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          }}
        />
        <ModalContent>
          <ModalHeader className="flex flex-col gap-spacing-xl">
            <div className='w-12 h-12 bg-brand-100 rounded-full flex items-center justify-center'>
              <IconComponent iconName='userPlus' className='w-5 h-4.5' color='#FFAC05'/>
            </div>
            <div className="flex flex-col gap-spacing-xs">
              <h2 className="font-semibold text-lg">Dodaj dane dziecka</h2>
              <p className="font-normal text-sm text-grayLight-600">Dodaj Profile dziecka lub dzieci</p>
            </div>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="grid gap-spacing-3xl">
            <CustomInput
              inputParams={{
                name: 'firstName',
                onChange: (e) => {
                  setNewKid((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                },
                required: true,
                pattern: "[A-Za-ząćęłńóśżźĄĆĘŁŃÓŚŻŹ]+"
              }}
              type="default"
              size="md"
              destructive={false}
              label="Imię*"
            />
            <CustomInput
              inputParams={{
                name: 'lastName',
                onChange: (e) => {
                  setNewKid((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                },
                required: true,
                pattern: "[A-Za-ząćęłńóśżźĄĆĘŁŃÓŚŻŹ]+"
              }}
              type="default"
              size="md"
              destructive={false}
              label="Nazwisko*"
            />
            { auth.availableTenants.length > 1 && (
              <div className="flex flex-col gap-spacing-md">
                <label className="text-sm font-medium text-grayLight-700">Szkoła</label>
                <Select
                  colorScheme="gray"
                  placeholder="Wybierz"
                  styles={customStyles}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setChosenTenantId(selectedOption.value);
                    }
                  }}
                  options={auth?.availableTenants.map((unit) => ({
                    ...unit,
                    label: unit.name,
                    value: unit.id
                  }))}
                />
              </div>
            )}
            <div className="flex flex-col gap-spacing-md">
              <label className="text-sm font-medium text-grayLight-700">Klasa</label>
              <Select
                colorScheme="gray"
                placeholder="Wybierz"
                styles={customStyles}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setNewKid((prev) => ({
                      ...prev,
                      organisationUnitId: selectedOption.value || null
                    }));
                  }
                }}
                options={consumerCreationContext?.orgUnits.map((unit) => ({
                  ...unit,
                  label: unit.name,
                  value: unit.id,
                }))}
              />
            </div>
            <CustomInput
              type="default"
              size="md"
              destructive={false}
              label="Numer identyfikatora"
              inputParams={{
                name: 'cardNumber',
                placeholder: 'Podaj numer identyfikatora',
                onChange: (e) => {
                  const target = e.target as HTMLInputElement;
                  const value = target.value.replace(/\D/g, '');
                  setNewKid((prev) => ({ ...prev, [target.name]: value }));
                },
                onInput: (e) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.replace(/\D/g, '');
                },
                pattern: "\\d{10}",
                title: "Numer identyfikatora musi składać się z dokładnie 10 cyfr lub może być pusty.",
                maxLength: 10,
                required: false,
              }}
            />
            <CustomInput
              type="default"
              size="md"
              destructive={false}
              label="Uwagi"
              inputParams={{
                name: 'remarks',
                placeholder: 'Wpisz uwagi na temat alegrenów dziecka',
                onChange: (e) => {
                  setNewKid((prev) => ({ ...prev, [e.target.name]: e.target.value }))
                },
                maxLength: 10,
                required: false,
              }}
            />
          </ModalBody>
          <ModalFooter className="w-full flex justify-between gap-spacing-lg">
            <CustomChakraButton
              hierarchy="secondaryColor"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow'
              }}
            >
              Wróć
            </CustomChakraButton>
            <CustomChakraButton
              hierarchy="primary"
              size="lg"
              iconPosition="onlyText"
              buttonProps={{
                className: 'grow',
                type: 'submit'
              }}
            >
              Zapisz
            </CustomChakraButton>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
};

export default ParentAddKidPopUp;