import React from "react";
import { MenuDivider, MenuItem } from '@chakra-ui/react';
import { Route, Routes } from "react-router-dom";
import Navigation from '../../component/Navigation/Navigation';
import ImportData from "../../component/SuperAdminComponents/ImportData/ImportData";
import { SideNavProvider } from '../../context/SideNavProvider';
import { NavOptionsInterface } from '../../shared/type/navOptions.type';
import IconComponent from '../../component/IconComponent/IconComponent';
import Companies from '../../component/SuperAdminComponents/Companies/Companies';
import Branches from '../../component/SuperAdminComponents/Branches/Branches';
import Statutes from '../../component/SuperAdminComponents/Statutes/Statutes';
import Settlements from '../../component/SuperAdminComponents/Settlements/Settlements';
import Superadmins from '../../component/SuperAdminComponents/Superadmins/Superadmins';
import WideHeader from '../../component/Headers/WideHeader/WideHeader';
import useAuth from '../../hooks/useAuth';

import './SuperAdminDashboard.css';

const navOptions: NavOptionsInterface[] = [
  {
    name: 'Oddziały',
    link: 'branches'
  },
  {
    name: 'Firmy',
    link: 'companies'
  },
  {
    name: 'Import danych',
    link: 'import-data'
  },
];

const SuperAdminDashboard = () => {
  const { logout } = useAuth();

  const menuList = (
    <>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="message" className="w-full h-full" />
          </div>
        }
      >
        Wiadomości
      </MenuItem>
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="settings" className="w-full h-full" />
          </div>
        }
      >
        Ustawienia
      </MenuItem>
      <MenuDivider />
      <MenuItem
        icon={
          <div className="Header__iconContainer">
            <IconComponent iconName="logOut" className="w-full h-full" />
          </div>
        }
        onClick={logout}
      >
        Wyloguj się
      </MenuItem>
    </>
  );

  return (
    <section className="SuperAdminDashboard flex flex-col w-screen h-screen overflow-scroll overflow-x-hidden">
      <div className="SuperAdminDashboard__header h-screen border border-gray-200">
        <WideHeader dropdownMenuList={menuList} />
      </div>
      <SideNavProvider>
        <div className="flex flex-col w-full flex-grow">
          <div className="SuperAdminDashboard__navigation">
            <Navigation navOptions={navOptions} />
          </div>
          <div className="SuperAdminDashboard__contextContainer flex-grow">
            <Routes>
              {/* Nawigacja jest inicjowna w sekcji branches */}
              <Route path="/branches/*" element={<Branches />} />
              <Route path="/companies/*" element={<Companies />} />
              <Route path="/statutes" element={<Statutes />} />
              <Route path="/settlements" element={<Settlements />} />
              <Route path="/superadmins" element={<Superadmins />} />
              <Route path="/import-data" element={<ImportData />} />
            </Routes>
          </div>
        </div>
      </SideNavProvider>
    </section>
  );
};

export default SuperAdminDashboard;
